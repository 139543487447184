import { Event } from "../../core/models/event";

export class Venue {
    id: string = "";
    title: string = "";
    slug: string = "";
    website: string = "";
    street_address: string = "";
    contribution: number = 0;
    events: Event[] = [];
    num_events: number = 0;
    city: string = "";
    image?: string = "";
    lat: number = 0;
    lng: number = 0;
    capacity: number = 85;
    g: any;
    email_notifications: boolean = false;
    emails_to_notify: string = '';
    created_at: any;
    updated_at: any;
}