import { createReducer, on } from "@ngrx/store";
import * as cartActions from "../actions/cart.actions";
import { CartTotals, TicketCartItem, TicketCartType, TicketItem } from "../../core/models/TicketCartItem";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TicketType } from "functions/src/models/TicketType";

export const adapter: EntityAdapter<TicketItem> = createEntityAdapter<TicketItem>({
    sortComparer: false,
});

export interface CartState {
    cart_items: TicketCartItem[];
    tickets: { [id: string]: TicketItem }
    totals: CartTotals,
    cart_count: number,
    ticket_count: number,
    cart_item_added: boolean;
    loading: boolean;
    loaded: boolean;
}

export const initialState: CartState = {
    cart_items: [],
    tickets: {},
    totals: new CartTotals(),
    cart_count: 0,
    ticket_count: 0,
    cart_item_added: false,
    loading: true,
    loaded: false
};

export const cartReducer = createReducer(
    initialState,
    on(cartActions.cartAddItem, (state, { ticketCartItem }) => {
        let cartCount = 0;
        let cartItems = [...state.cart_items];
        let existingItem = cartItems.filter(item => {
            return item.ticket_type.id === ticketCartItem.ticket_type.id && item.event.id === ticketCartItem.event.id;
        });

        if(existingItem.length > 0) {
            const currentQuantity = existingItem[0].quantity;
            const increaseBy = ticketCartItem.quantity;

            let newItem = Object.assign({
                ...existingItem[0],
                quantity: currentQuantity + increaseBy,
            });

            const itemIndex = cartItems.indexOf(existingItem[0]);
            cartItems.splice(itemIndex, 1);
            cartItems.push(newItem);
        } else {
            cartItems.push(ticketCartItem);
        }

        cartItems.forEach(cartItem => {
            cartCount += cartItem.quantity;
        });

        localStorage.setItem("user_cart", JSON.stringify(cartItems));

        return {
            ...state,
            cart_items: cartItems,
            cart_count: cartCount
        }
    }),
    on(cartActions.cartChangeQuantity, (state, {ticketCartItem, increase}) => {
        let cartCount: number = 0;
        let cartItems = [...state.cart_items];
        let existingItem = cartItems.find(item => {
            return item.ticket_type === ticketCartItem.ticket_type && item.event.id === ticketCartItem.event.id;
        });

        if(existingItem) {
            let newItem = Object.assign({
                ...existingItem,
                quantity: ticketCartItem.quantity,
            });

            const itemIndex = cartItems.indexOf(existingItem);
            cartItems.splice(itemIndex, 1);
            cartItems.push(newItem);
        } else {
            cartItems.push(ticketCartItem);
        }

        cartItems.forEach(cartItem => {
            cartCount += cartItem.quantity;
        });
        localStorage.setItem("user_cart", JSON.stringify(cartItems));

        return {
            ...state,
            cart_items: cartItems,
            cart_count: cartCount
        }
    }),
    on(cartActions.cartTogglePreview, (state, {finished}) => {
        return {
            ...state,
            cart_item_added: finished
        }
    }),
    on(cartActions.cartRemoveItem, (state, {cartItem}) => {
        let itemIndex: number = -1;
        let filterItems: any = state.cart_items.find((item, index) => {
            if(item.ticket_type === cartItem.ticket_type && item.event.id === cartItem.event.id) {
                itemIndex = index;
                return;
            }
        });

        if(itemIndex > -1) {
            state.cart_items.splice(itemIndex, 1);
            localStorage.setItem("user_cart", JSON.stringify(state.cart_items));
        }

        return {
            ...state,
            cart_count: state.cart_items.length
        }
    }),
    on(cartActions.cartEmptyItems, (state, {removeAll}) => {
        localStorage.setItem("user_cart", JSON.stringify([]));

        return {
            ...state,
            cart_items: [],
            cart_count: 0
        }
    }),
    on(cartActions.cartAddTicket, (state, {ticketCartItem, increase, availableTickets}) => {
        const eventTickets = state.tickets[ticketCartItem.event.id];
        const ticketTypeId = Object.keys(ticketCartItem.ticket_types)[0];
        const newTicketType = new TicketType();
        newTicketType.created_at = ticketCartItem.ticket_types[ticketTypeId].ticket_type.created_at;
        newTicketType.description = ticketCartItem.ticket_types[ticketTypeId].ticket_type.description;
        newTicketType.dynamic = ticketCartItem.ticket_types[ticketTypeId].ticket_type.dynamic;
        newTicketType.dynamic_map = ticketCartItem.ticket_types[ticketTypeId].ticket_type.dynamic_map;
        newTicketType.id = ticketCartItem.ticket_types[ticketTypeId].ticket_type.id;
        newTicketType.name = ticketCartItem.ticket_types[ticketTypeId].ticket_type.name;
        newTicketType.price = ticketCartItem.ticket_types[ticketTypeId].ticket_type.price;
        newTicketType.ticket_tiers = ticketCartItem.ticket_types[ticketTypeId].ticket_type.ticket_tiers;
        newTicketType.title = ticketCartItem.ticket_types[ticketTypeId].ticket_type.title;
        newTicketType.updated_at = ticketCartItem.ticket_types[ticketTypeId].ticket_type.updated_at;
        newTicketType.vip = ticketCartItem.ticket_types[ticketTypeId].ticket_type.vip;

        if(!eventTickets && increase) {
            state.tickets[ticketCartItem.event.id] = new TicketItem();
            state.tickets[ticketCartItem.event.id].event = ticketCartItem.event;
            state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`] = new TicketCartType();
            state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].quantity = 1;
            state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].ticket_type = newTicketType;

            if(state.tickets[ticketCartItem.event.id].total_quantity + 1 <= availableTickets) {
                state.tickets[ticketCartItem.event.id].total_quantity++;
            }
        } else {
            if(eventTickets.ticket_types[`${ticketTypeId}`]) {
                if(increase) {
                    if(state.tickets[ticketCartItem.event.id].total_quantity + 1 <= availableTickets) {
                        state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].quantity++;
                        state.tickets[ticketCartItem.event.id].total_quantity++;
                    }
                } else if(!increase && state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].quantity > 0) {
                    state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].quantity--;
                    state.tickets[ticketCartItem.event.id].total_quantity--;
                    if(state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].quantity === 0) {
                        delete state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`];

                        if(Object.keys(state.tickets[ticketCartItem.event.id].ticket_types).length === 0) {
                            delete state.tickets[ticketCartItem.event.id];
                        }
                    }
                }
            } else if(increase) {
                if(state.tickets[ticketCartItem.event.id].total_quantity + 1 <= availableTickets) {
                    state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`] = new TicketCartType();
                    state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].quantity = 1;
                    state.tickets[ticketCartItem.event.id].total_quantity++;
                    state.tickets[ticketCartItem.event.id].ticket_types[`${ticketTypeId}`].ticket_type = newTicketType;
                }
            }
        }

        localStorage.setItem('user_tickets', JSON.stringify(state.tickets));

        return {
            ...state
        }
    }),
    on(cartActions.cartCalculateTotals, (state) => {
        let subtotal: number = 0;
        let fees: number = 0;

        Object.keys(state.tickets).forEach(key => {
            const eventTickets = state.tickets[key];

            Object.keys(eventTickets.ticket_types).forEach(key => {
                const ticket = eventTickets.ticket_types[key];
                subtotal += ticket.quantity * ticket.ticket_type.price;
                fees += ticket.quantity * 3;
            })
        });

        state.totals = {
            subtotal: subtotal,
            fees: fees,
            total: subtotal + fees,
            taxes: 0,
            discount: 0
        };
        return {
            ...state,
        }
    }),
    on(cartActions.cartSetTicketState, (state) => {
        const userTickets = JSON.parse(localStorage.getItem('user_tickets')!);

        if(!userTickets) {
            return {
                ...state
            }
        }

        return {
            ...state,
            tickets: userTickets
        }
    }),
    on(cartActions.cartCountTickets, (state) => {
        let ticketCount = 0;

        Object.keys(state.tickets).forEach(key => {
            Object.keys(state.tickets[key].ticket_types).forEach(ticketKey => {
                ticketCount += state.tickets[key].ticket_types[ticketKey].quantity
            })
        });

        return {
            ...state,
            ticket_count: ticketCount
        }
    }),
    on(cartActions.cartEmptyTickets, (state) => {
        localStorage.removeItem('user_tickets');
        
        return {
            ...state,
            tickets: {},
            cart_count: 0,
            ticket_count: 0,
            totals: new CartTotals(),
        }
    })
);

export const getCartState = (state: CartState) => state;
export const getCartItems = (state: CartState) => state.cart_items;

export const {
    selectIds: selectTicketCartIds,
    selectEntities: selectTicketCartEntities,
    selectAll: selectAllTicketCartItems,
    selectTotal: ticketCartCount,
  } = adapter.getSelectors();