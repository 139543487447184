import { UserEffects } from "./user.effects";
import { TicketEffects } from "./ticket.effects";
import { EventEffects } from "./event.effects";
import { BlogEffects } from "./blog.effects";
import { CartEffects } from "./cart.effects";
import { VenueEffects } from "./venue.effects";
import { AppEffects } from "./app.effects";
import { SettingsEffects } from "./settings.effects";
import { CardEffects } from "./card.effects";
import { GigSheetEffects } from "./gigsheet.effects";
import { AdminEventEffects } from "./admin.events.effects";
import { AdminTicketsEffects } from "./admin.tickets.effects";
import { MediaEffects } from "./media.effects";
import { AdminFinancialReportsEffects } from "./admin.financial.reports.effects";
import { AdminTaskEffects } from "./admin.task.effects";

export const effects = [
    AppEffects,
    UserEffects,
    TicketEffects,
    EventEffects,
    CartEffects,
    BlogEffects,
    VenueEffects,
    SettingsEffects,
    CardEffects,
    GigSheetEffects,
    AdminEventEffects,
    AdminTicketsEffects,
    AdminFinancialReportsEffects,
    AdminTaskEffects,
    MediaEffects
];