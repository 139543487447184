import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from 'src/app/core/models/user';
import * as userActions from '../actions/user.actions';
import * as settingsActions from '../actions/settings.actions';
import * as cartActions from '../actions/cart.actions';
import { UserService } from 'src/app/core/services/user/user.service';

@Injectable()
export class UserEffects {
    user: any;
    userService: UserService = inject(UserService);

    constructor(
        private actions$: Actions,
        private auth: AngularFireAuth,
        private afs: AngularFirestore
    ) {}

    registerUserStart$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userRegisterStart),
        mergeMap((action) => this.auth.createUserWithEmailAndPassword(action.email, action.password).then(response => {
            return userActions.userCreateRecord({password: action.password, data: {first_name: action.first_name, last_name: action.last_name, email: action.email, role: "customer", uid: response?.user?.uid, created_at: new Date(), updated_at: new Date()}})
        }).catch(error => {
            return userActions.userLogInComplete({success: false, error: error.message})
        }))
    ), {dispatch: true});

    logInUser$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userLogInStart),
        mergeMap((action) => this.auth.signInWithEmailAndPassword(action.email, action.password).then(response => {
            localStorage.setItem("comedystand-user", JSON.stringify({uid: response?.user?.uid as string, email: action.email}));
            return userActions.userGetDataStart({uid: response?.user?.uid as string})
        }).catch(error => {
            return userActions.userLogInComplete({success: false, error: error.message})
        }))
    ), {dispatch: true});

    logOutStart$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userLogOutStart),
        mergeMap(action => this.auth.signOut().then(() => {
            localStorage.removeItem("comedystand-user");
            localStorage.removeItem("comedystand-uid");
            localStorage.removeItem("user_cart");
            localStorage.removeItem("guest_user");
            cartActions.cartEmptyItems({removeAll: true});
            return userActions.userLogOutComplete({success: true});
        }).catch(() => {
            return userActions.userLogOutComplete({success: false});
        }))
    ), {dispatch: true});

    logOutComplete$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userLogOutComplete),
        map(() => {
            return cartActions.cartEmptyItems({removeAll: true})
        })
    ), {dispatch: true});

    getUserDataStart$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userGetDataStart),
        tap(async (action) => {
            this.user = await this.auth.currentUser;
        }),
        mergeMap((action) => this.afs.collection("users").doc(action.uid).get().pipe(
            mergeMap(data => {
                const user = data.data() as User;

                return [
                    userActions.userGetDataComplete({user: user}),
                    settingsActions.loadUserSettingsComplete({notificationSettings: user.notification_settings})
                ];
            }),
            catchError((error) => {
                return of(userActions.userCreateRecordAfterLogIn({user: this.user}))
            })
        ))
    ), {dispatch: true});

    createUserAfterLogIn$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userCreateRecordAfterLogIn),
        mergeMap(action => this.afs.collection("users").doc(action.user.uid).set({
                first_name: (action.user.displayName) ? action.user.displayName.split(" ")[0] : "",
                last_name: (action.user.displayName) ? action.user.displayName.split(" ")[1] : "",
                email: action.user.email,
                role: "customer",
                uid: action.user.uid,
                payment_methods: [],
                created_at: new Date(),
                updated_at: new Date()
            }).then(() => {
                return userActions.userGetDataStart({uid: action.user.uid as string})
            })
        )
    ), {dispatch: true});

    createUserRecordStart$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userCreateRecord),
        mergeMap(action => this.afs.collection("users").doc(action.data.uid).set(action.data).then(() => {
            return userActions.userLogInStart({email: action.data.email, password: action.password})
        }).catch(async (error) => {
            const user = await this.auth.currentUser;
            return userActions.userLogInComplete({success: false, error: error.message})
        }))
    ), {dispatch: true})

    createGuestUserRecordStart$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userCreateGuest),
        mergeMap(action => this.afs.collection("users_guest").doc(action.sessionId).set({
            ip_address: action.ipAddress,
            session_id: action.sessionId,
            cart: [],
            created_at: new Date(),
            last_seen: new Date()
        }).then(() => {
        }).catch((error) => {
        }))
    ), {dispatch: false})

    userUpdateProfile$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userUpdateProfile),
        mergeMap(async (action) => {
            const user = JSON.parse(localStorage.getItem('comedystand-user')!);
            return await this.userService.updateUser(user.uid, action.userData).then(response => {
                return userActions.userUpdateProfileComplete()
            });
        }),
    ))

    userRetrieveGuest$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userRetrieveGuest),
        tap(action => {
        }),
        mergeMap(action => this.afs.collection("users_guest").doc(action.user.session_id).set({
            ip_address: action.user.ip_address,
            session_id: action.user.session_id,
            last_seen: new Date()
        }, {merge: true}).then(() => {
        }).catch((error) => {
        }))
    ), {dispatch: false})

    userUpdateGuestCart$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userUpdateGuestCart),
        tap(action => {
        }),
        map(action => {
            const collection = (action.user.session_id) ? "users_guest" : "users";
            const userId = (action.user.session_id) ? action.user.session_id : action.user.uid;
            this.afs.collection(collection).doc(userId).set({cart: action.cart}, {merge: true});
        })
    ), {dispatch: false})

    userUpdateGuest$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userUpdateGuest),
        map(action => {
            this.afs.collection("users_guest").doc(action.userData.session_id).set(action.userData, {merge: true});
        })
    ), {dispatch: false})

    userRemovePaymentMethod$ = createEffect(() => this.actions$.pipe(
        ofType(userActions.userRemovePaymentMethod),
        mergeMap(action => {
            const methodIndex = action.userData.payment_methods.findIndex(card => card.id == action.cardId);
            let userData = action.userData;
            
            if(methodIndex !== -1) {
                userData.payment_methods.splice(methodIndex, 1);
            }

            return [
                userActions.userUpdateProfile({userData: userData}),
                settingsActions.settingsSetSaving({saving: false})
            ]
        })
    ), {dispatch: true})
}