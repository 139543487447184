import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromTickets from '../reducers/ticket.reducers';

export const getRootTicketState = (state: fromRoot.GlobalAppState) => state.tickets;
export const getAllTickets = createSelector(getRootTicketState, fromTickets.getTickets);
export const getTicketsLoading = createSelector(getRootTicketState, fromTickets.getTicketsLoading);
export const getSingleTicket = (id: string) => createSelector(getAllTickets, (allTickets) => {
    if(allTickets) {
        return allTickets.filter(ticket => {
            return ticket.id === id;
        })
    } else {
        return [];
    }
});
export const getUpcomingTickets = createSelector(getAllTickets, (allTickets) => {
    if(allTickets) {
        return allTickets.filter(ticket => {
            return ticket.event_date.seconds * 1000 > new Date().getTime();
        })
    } else {
        return [];
    }
});