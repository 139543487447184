import * as fromApp from "./app.reducers";
import * as fromUser from "./user.reducers";
import * as fromTickets from "./ticket.reducers";
import * as fromEvents from "./event.reducers";
import * as fromBlog from "./blog.reducers";
import * as fromCart from "./cart.reducers";
import * as fromVenues from "./venue.reducers";
import * as fromSettings from "./settings.reducers";
import * as fromCards from "./card.reducers";
import * as fromGigSheets from "./gigsheet.reducers";
import * as fromMedia from "./media.reducers";
import * as fromAdminEvents from "./admin.event.reducers";
import * as fromAdminTickets from "./admin.ticket.reducers";
import * as fromAdminFinancialReports from "./admin.financial.reports.reducers";
import * as fromAdminTasks from "./admin.task.reducers";
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface GlobalAppState {
    app: fromApp.AppState,
    user: fromUser.UserState,
    tickets: fromTickets.TicketState,
    events: fromEvents.EventState,
    venues: fromVenues.VenueState,
    blog: fromBlog.BlogState,
    cart: fromCart.CartState,
    settings: fromSettings.SettingsState,
    cards: fromCards.CardState,
    gigsheets: fromGigSheets.GigSheetState,
    media: fromMedia.MediaState,
    admin_events: fromAdminEvents.AdminEventsState,
    admin_tasks: fromAdminTasks.AdminTasksState,
    admin_tickets: fromAdminTickets.AdminTicketsState,
    admin_financial_reports: fromAdminFinancialReports.AdminFinancialReportsState
}

export const reducers: ActionReducerMap<GlobalAppState> = {
  app: fromApp.appReducer,
  user: fromUser.userReducer,
  tickets: fromTickets.ticketReducer,
  events: fromEvents.eventsReducer,
  venues: fromVenues.venueReducer,
  blog: fromBlog.blogReducer,
  cart: fromCart.cartReducer,
  settings: fromSettings.settingsReducer,
  cards: fromCards.cartReducer,
  gigsheets: fromGigSheets.gigSheetsReducer,
  media: fromMedia.mediaReducer,
  admin_events: fromAdminEvents.adminEventsReducer,
  admin_tasks: fromAdminTasks.adminTasksReducer,
  admin_tickets: fromAdminTickets.adminTicketsReducer,
  admin_financial_reports: fromAdminFinancialReports.adminFinancialReportsReducer,
}

export const getAppState = createFeatureSelector<
  fromUser.UserState
>('app');

export const getUserState = createFeatureSelector<
  fromUser.UserState
>('user');

export const getTicketState = createFeatureSelector<
  fromTickets.TicketState
>('tickets');

export const getEventsState = createFeatureSelector<
  fromEvents.EventState
>('events');

export const getBlogState = createFeatureSelector<
  fromBlog.BlogState
>('blog');

export const getCartState = createFeatureSelector<
  fromCart.CartState
>('cart');

export const getVenueState = createFeatureSelector<
  fromVenues.VenueState
>('venues');

export const getSettingsState = createFeatureSelector<
  fromSettings.SettingsState
>('settings');

export const getGigSheetState = createFeatureSelector<
  fromGigSheets.GigSheetState
>('gigsheets');

export const getAdminEventsState = createFeatureSelector<
  fromAdminEvents.AdminEventsState
>('admin_events');

export const getAdminTasksState = createFeatureSelector<
  fromAdminTasks.AdminTasksState
>('admin_tasks');

export const getAdminTicketsState = createFeatureSelector<
  fromAdminTickets.AdminTicketsState
>('admin_tickets');

export const getAdminFinancialReportsState = createFeatureSelector<
  fromAdminFinancialReports.AdminFinancialReportsState
>('admin_financial_reports');

export const getMediaState = createFeatureSelector<
  fromMedia.MediaState
>('media');