import { createAction, props } from '@ngrx/store';
import { Venue } from 'functions/src/models/Venue';
import { Event } from 'src/app/core/models/event';

export const loadVenuesStart = createAction(
    '[Venues] Start to Load Venues'
);

export const loadVenuesComplete = createAction(
    '[Venues] Finished Loading Venues Complete',
    props<{venues: any}>()
);

export const loadVenueEventsStart = createAction(
    '[Venues] Start Loading Venue Events',
    props<{venueIds: string[]}>()
);

export const loadVenueEventsComplete = createAction(
    '[Venues] Finished Loading Venue Events',
    props<{events: Event[]}>()
);