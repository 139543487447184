import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { QuerySnapshot } from '@google-cloud/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VenueService {

  constructor(
    private afs: AngularFirestore
  ) { }

  fetchAllVenues(): Observable<unknown> {
    return this.afs.collection('venues').get();
  }
}
