import { createAction, props } from '@ngrx/store';
import { Ticket } from 'src/app/core/models/ticket';

export const loadAdminOnlineSalesStart = createAction(
    '[Admin] Start to Load Admin Online Sales'
);

export const loadAdminOnlineSalesSuccess = createAction(
    '[Admin] Successfully Loaded Admin Online Sales',
    props<{tickets: Ticket[]}>()
);

export const loadTicketSalesFailure = createAction(
    '[Admin] Load Ticket Sales Failure',
    props<{ error: any }>()
);

export const changeAdminTicketChartRange = createAction(
    '[Admin] Change Admin Ticket Charts Date Range',
    props<{ days: number }>()
);