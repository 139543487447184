import { createReducer, on } from "@ngrx/store";
import * as mediaActions from "../actions/media.actions";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Media } from "src/app/core/models/media";

export const adapter: EntityAdapter<Media> = createEntityAdapter<Media>({
    sortComparer: false,
    selectId: (media: Media) => media.id!,
});

export interface MediaState extends EntityState<Media> {
    loading: boolean;
    loaded: boolean;
    saving: boolean;
    saved: boolean;
    error: any;
}

export const initialState: MediaState = {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
    saving: false,
    saved: false,
    error: null
};

export const mediaReducer = createReducer(
    initialState,
    on(mediaActions.loadMediaStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(mediaActions.loadMediaSuccess, (state, { media }) => {
        return adapter.setAll(media, {
            ...state,
            loaded: true,
            loading: false,
            saved: false,
        });
    }),
    on(mediaActions.storeMediaStart, (state, {}) => {
        return {
            ...state,
            saving: true,
            saved: false,
        }
    }),
    on(mediaActions.storeMediaSuccess, (state, {media}) => {
        return adapter.addOne(media, {
            ...state,
            saving: false
        })
    }),
    on(mediaActions.storeMediaInFirestoreSuccess, (state, {}) => {
        return {
            ...state,
            saved: true
        }
    }),
    on(mediaActions.deleteMedia, (state) => ({
        ...state,
        saving: true,
        saved: false,
        error: null,
    })),
    on(mediaActions.deleteMediaSuccess, (state) => ({
        ...state,
        saving: false,
        saved: true,
    })),
    on(mediaActions.deleteMediaFailure, (state, { error }) => ({
        ...state,
        saving: false,
        saved: false,
        error,
    }))
);

export const getMediaState = (state: MediaState) => state;
export const getMediaLoading = (state: MediaState) => state.loading;
export const getMediaSaving = (state: MediaState) => state.saving;
export const getMediaSaved = (state: MediaState) => state.saved;
export const {
    selectIds: selectMediaIds,
    selectEntities: selectMediaEntities,
    selectAll: selectAllMedia,
    selectTotal: adminMediaCount,
  } = adapter.getSelectors();