import { createReducer, on } from "@ngrx/store";
import { Post } from "src/app/core/models/post";
import * as blogActions from "../actions/blog.actions";

export interface BlogState {
    blog_data: Post[];
    loading: boolean;
    loaded: boolean;
}

export const initialState: BlogState = {
    blog_data: [],
    loading: true,
    loaded: false
};

export const blogReducer = createReducer(
    initialState,
    on(blogActions.loadBlogPostsStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(blogActions.loadingBlogPostsComplete, (state, { posts }) => {
        return {
            ...state,
            blog_data: posts,
            loading: false,
            loaded: true
        }
    })
);

export const getBlogState = (state: BlogState) => state;
export const getBlogPosts = (state: BlogState) => state.blog_data;