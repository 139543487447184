import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Ticket } from 'src/app/core/models/ticket';
import * as adminTicketsActions from '../actions/admin.tickets.actions';
import { of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class AdminTicketsEffects {
    actualDate: Date = new Date();
    private afs: AngularFirestore = inject(AngularFirestore);

    constructor(
        private actions$: Actions,
    ) {}

    startGettingOnlineSales$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(adminTicketsActions.loadAdminOnlineSalesStart),
          mergeMap(payload =>
            this.afs.collection<Ticket>('tickets').get().pipe(
              map((querySnapshot) => {
                const tickets = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                } as Ticket));
                return adminTicketsActions.loadAdminOnlineSalesSuccess({ tickets });
              }),
              catchError((error) => of(adminTicketsActions.loadTicketSalesFailure({ error })))
            )
          )
        );
      }, { dispatch: true });
}