import { createReducer, on } from "@ngrx/store";
import * as cardActions from "../actions/card.actions";
import { Card } from "src/app/core/models/card";

export interface CardState {
    cards: { [id: string]: Card };
    saving: boolean;
    saved: boolean;
    deleted: boolean;
    error: string;
}

export const initialState: CardState = {
    cards: {},
    saving: false,
    saved: false,
    deleted: false,
    error: ""
};

export const cartReducer = createReducer(
    initialState,
    on(cardActions.cardAddItemStart, (state, { card }) => {
        return {
            ...state,
            saving: true,
            saved: false,
            error: ""
        }
    }),
    on(cardActions.cardAddItemSuccess, (state, { response }) => {
        return {
            ...state,
            saving: false,
            saved: true
        }
    }),
    on(cardActions.cardAddItemFail, (state, { response }) => {
        return {
            ...state,
            saving: false,
            saved: false,
            error: response
        }
    }),
    on(cardActions.cardRemoveItemSuccess, (state, {}) => {
        return {
            ...state,
            deleted: true
        }
    }),
    on(cardActions.cardResetDeleted, (state, {}) => {
        return {
            ...state,
            deleted: false
        }
    }),
);

export const getCardState = (state: CardState) => state;
export const getCards = (state: CardState) => state.cards;
export const getCardSuccess = (state: CardState) => state.saved;