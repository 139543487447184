import { createAction, props } from '@ngrx/store';
import { TicketCartItem, TicketItem } from '../../core/models/TicketCartItem';

export const cartAddItem = createAction(
    '[Cart] Add Item to Cart',
    props<{ticketCartItem: TicketCartItem}>()
);

export const cartChangeQuantity = createAction(
    '[Cart] Change Cart Quantity',
    props<{ticketCartItem: TicketCartItem, increase: boolean}>()
);

export const cartTogglePreview = createAction(
    '[Cart] Toggle Cart Preview',
    props<{finished: boolean}>()
);

export const cartContinueShopping = createAction(
    '[Cart] Continue Shopping',
    props<{finished: boolean}>()
);

export const cartRemoveItem = createAction(
    '[Cart] Remove Item From Cart',
    props<{cartItem: TicketCartItem}>()
);

export const cartEmptyItems = createAction(
    '[Cart] Remove All Items From Cart',
    props<{removeAll: boolean}>()
);

export const cartAddTicket = createAction(
    '[Cart] Add Ticket To Cart',
    props<{ticketCartItem: TicketItem, increase: boolean, availableTickets: number}>()
);

export const cartSetTicketState = createAction(
    '[Cart] Set Ticket State'
);

export const cartEmptyTickets = createAction(
    '[Cart] Empty Cart Tickets'
);

export const cartCountTickets = createAction(
    '[Cart] Count Cart Tickets'
);

export const cartCalculateTotals = createAction(
    '[Cart] Calculate Totals'
)