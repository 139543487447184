import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Venue } from 'src/app/core/models/venue';
import * as venueActions from '../actions/venue.actions';
import { VenueService } from 'src/app/core/services/venue/venue.service';
import { QuerySnapshot } from '@google-cloud/firestore';
import { of } from 'rxjs';

@Injectable()
export class VenueEffects {
    actualDate: Date = new Date();
    venueService = inject(VenueService);

    constructor(
        private actions$: Actions,
    ) {
        
    }

    startGettingVenues$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(venueActions.loadVenuesStart),
          switchMap(payload =>
            this.venueService.fetchAllVenues().pipe(
              map(data => data),
              catchError(err => of(null))
            )
          ),
          concatMap(data => {
            const response = data as QuerySnapshot;
            const venues: Venue[] = [];
            const venueIds: string[] = [];

            response.docs.forEach(venue => {
                const venueData = venue.data() as Venue;
                venues.push({
                  ...venueData,
                  num_events: 0
                });
                venueIds.push(venueData.id);
            })

            return [
                venueActions.loadVenuesComplete({ venues: venues}),
            ];
          })
        );
      }, { dispatch: true });
}