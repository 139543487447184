import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { Media } from '../../models/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  afs: AngularFirestore = inject(AngularFirestore);

  fetchMedia(): Observable<unknown> {
    return this.afs.collection('media', ref=>ref.orderBy('created_at', 'desc'))
      .get();
  }

  saveMedia(media: Media): Observable<unknown> {
    return from(this.afs.collection('media')
      .doc(media.id)
      .set(media));
  }

  deleteMediaFromFirestore(mediaId: string): Observable<unknown> {
    return from(this.afs.collection('media')
      .doc(mediaId)
      .delete());
  }
}
