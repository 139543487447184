import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromMedia from '../reducers/media.reducers';
import { Media } from 'src/app/core/models/media';
import { Timestamp } from '@angular/fire/firestore';

export const getRootMediaState = (state: fromRoot.GlobalAppState) => state.media;
export const selectAllMediaEntities = createSelector(
    getRootMediaState,
    fromMedia.selectMediaEntities
  );
export const selectMediaLoading = createSelector(getRootMediaState, fromMedia.getMediaLoading);
export const selectMediaSaving = createSelector(getRootMediaState, fromMedia.getMediaSaving);
export const selectMediaSaved = createSelector(getRootMediaState, fromMedia.getMediaSaved);
export const selectMediaArray = () => createSelector(getRootMediaState, (mediaState) => {
    const mediaArray: Media[] = [];

    if(mediaState.entities) {
        Object.keys(mediaState.entities).forEach(key => {
            mediaArray.push(mediaState.entities[key]!);
        });

        mediaArray.sort((a, b) => {
            const dateA = (a.created_at as Timestamp).toDate().getTime();
            const dateB = (b.created_at as Timestamp).toDate().getTime();
            return dateB - dateA; // Descending order
        });
    }
    
    return mediaArray;
});