import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Event } from 'src/app/core/models/event';
import * as eventActions from '../actions/event.actions';
import * as venueActions from '../actions/venue.actions';
import { EventService } from 'src/app/core/services/events/event.service';
import { of } from 'rxjs';
import { QuerySnapshot } from '@google-cloud/firestore';

@Injectable()
export class EventEffects {
    actualDate: Date = new Date();
    private eventService = inject(EventService);

    constructor(
        private actions$: Actions,
    ) {}

    startGettingEvents$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(eventActions.upcomingEventsStart),
          switchMap(payload =>
            this.eventService.fetchUpcomingEvents().pipe(
              map(data => data),
              catchError(err => of(null))
            )
          ),
          concatMap(data => {
            const response = data as QuerySnapshot;
            const events: Event[] = [];

            response.docs.forEach(event => {
                const eventData = event.data() as Event;
                events.push(eventData);
            })

            return [
                eventActions.upcomingEventsComplete({ events: events}),
                venueActions.loadVenueEventsComplete({ events: events })
            ];
          })
        );
      }, { dispatch: true });
}