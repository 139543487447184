import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import * as appActions from '../actions/app.actions';
import * as eventActions from '../actions/event.actions';
import * as venueActions from '../actions/venue.actions';
import * as ticketActions from '../actions/ticket.actions';
import { of } from 'rxjs';
import { QuerySnapshot } from '@google-cloud/firestore';
import { VenueService } from 'src/app/core/services/venue/venue.service';
import { Venue } from 'src/app/core/models/venue';

@Injectable()
export class AppEffects {
    private venueService = inject(VenueService);

    constructor(
        private actions$: Actions,
    ) {}

    startGettingVenues$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(appActions.loadAppDataStart),
          switchMap(payload =>
            this.venueService.fetchAllVenues().pipe(
              map(data => data),
              catchError(err => {
                return of(null)
              })
            )
          ),
          concatMap(data => {
            const response = data as QuerySnapshot;
            const venues: Venue[] = [];

            if(!response.empty) {
              response.docs.forEach(venue => {
                  const venueData = venue.data() as Venue;
                  venues.push(venueData);
              })
            }

            return [
              venueActions.loadVenuesComplete({ venues: venues }),
              appActions.setAdminSidebar(),
              eventActions.upcomingEventsStart()
            ];
          })
        );
      }, { dispatch: true });
}