import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as cartActions from '../actions/cart.actions';
import * as userActions from '../actions/user.actions';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class CartEffects {
    private userCart: any;
    private user: any;

    constructor(
        private actions$: Actions,
        private afs: AngularFirestore,
        private auth: AngularFireAuth,
    ) {
        
    }
    
    updateUserCart$ = createEffect(() => this.actions$.pipe(
        ofType(cartActions.cartAddItem),
        tap(action => {
            const userCart = localStorage.getItem("user_cart");
            const guestUser = localStorage.getItem("guest_user");
            const authUser = localStorage.getItem("comedystand-user");
            this.userCart = JSON.parse((userCart) ? userCart : "[]");
            this.user = (guestUser) ? JSON.parse(guestUser) : JSON.parse(authUser!!);
        }),
        map(action => {
            return userActions.userUpdateGuestCart({ cart: this.userCart, user: this.user })
        })
    ), {dispatch: true});

    countCartTickets$ = createEffect(() => this.actions$.pipe(
        ofType(cartActions.cartSetTicketState, cartActions.cartAddTicket),
        mergeMap(action => {
            return [
                cartActions.cartCountTickets(),
                cartActions.cartCalculateTotals()
            ];
        })
    ));
    
    emptyCartItems$ = createEffect(() => this.actions$.pipe(
        ofType(cartActions.cartEmptyItems),
        tap(action => {
            const guestUser = localStorage.getItem("guest_user");
            const authUser = localStorage.getItem("comedystand-user");
            this.user = (guestUser) ? JSON.parse(guestUser) : JSON.parse(authUser!!);
        }),
        map(action => {
            return userActions.userUpdateGuestCart({ cart: [], user: this.user })
        })
    ), {dispatch: true});
}