import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { Event } from 'src/app/core/models/event';
import * as adminEventActions from '../actions/admin.event.actions';
import { of } from 'rxjs';
import { QuerySnapshot } from '@google-cloud/firestore';
import { GigSheet } from 'src/app/core/models/gigsheet';
import { EventService } from 'src/app/core/services/events/event.service';

@Injectable()
export class AdminEventEffects {
    actualDate: Date = new Date();
    private eventService: EventService = inject(EventService);

    constructor(
        private actions$: Actions,
    ) {}

    startGettingGigSheets$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(adminEventActions.loadAdminEventsStart),
          switchMap(payload =>
            this.eventService.fetchAllEvents().pipe(
              map(data => data),
              catchError(err => of(null))
            )
          ),
          concatMap(data => {
            const response = data as QuerySnapshot;
            const events: Event[] = [];

            if(response) {
              response.docs.forEach(gigsheet => {
                const eventData = gigsheet.data() as Event;
                events.push(eventData);
              })
            }

            return [
                adminEventActions.loadAdminEventsSuccess({ events: events}),
            ];
          })
        );
      }, { dispatch: true });
}