import { createFeatureSelector, createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromCards from '../reducers/card.reducers';

// export const getRootCardState = (state: fromRoot.GlobalAppState) => state.cards;
// export const getAllCards = createSelector(getRootCardState, fromCards.getCards);

export const selectCardState = createFeatureSelector<fromCards.CardState>('cards');

export const selectSaving = createSelector(
  selectCardState,
  (state: fromCards.CardState) => state.saving
);

export const selectSaved = createSelector(
  selectCardState,
  (state: fromCards.CardState) => state.saved
);

export const selectDeleted = createSelector(
  selectCardState,
  (state: fromCards.CardState) => state.deleted
);

// export const getSingleCard = (id: string) => createSelector(getAllCards, (allCards) => {
//     if(allCards) {
//         return allCards[id];
//     } else {
//         return {};
//     }
// });