import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as blogActions from '../actions/blog.actions';
import { Post } from 'src/app/core/models/post';

@Injectable()
export class BlogEffects {
    actualDate: Date = new Date();

    constructor(
        private actions$: Actions,
        private afs: AngularFirestore
    ) {
        
    }

    getBlogPostsStart$ = createEffect(() => this.actions$.pipe(
        ofType(blogActions.loadBlogPostsStart),
        tap(() => {
        }),
        mergeMap((action) => this.afs.collection("blog", ref => ref.orderBy("created_at", "desc")).valueChanges().pipe(
            map(data => {
                let eventData = data as unknown;
                return blogActions.loadingBlogPostsComplete({posts: eventData as Post[]})
            })
        ))
    ), {dispatch: true});
}