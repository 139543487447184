import { createReducer, on } from "@ngrx/store";
import { Event } from "src/app/core/models/event";
import * as eventActions from "../actions/event.actions";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({ sortComparer: false });

export interface EventState extends EntityState<Event> {
    ids: string[];
    entities: { [id: string ]: Event };
    loading: boolean;
    loaded: boolean;
    lastUpdated: Date | null;
}

export const initialState: EventState = {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
    lastUpdated: null,
};

export const eventsReducer = createReducer(
    initialState,
    on(eventActions.upcomingEventsStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(eventActions.upcomingEventsComplete, (state, { events }) => {
        const update = adapter.setAll(events, state);
        return {
            ...update,
            loaded: true,
            loading: false,
            lastUpdated: new Date(),
        };
    })
);

export const getEventsState = (state: EventState) => state;
export const getEventsLoading = (state: EventState) => state.loading;
export const {
    selectIds: selectEventIds,
    selectEntities: selectEventEntities,
    selectAll: selectAllEvents,
    selectTotal: eventsCount,
  } = adapter.getSelectors();