import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Media } from 'src/app/core/models/media';
import * as mediaActions from '../actions/media.actions';
import { of } from 'rxjs';
import { QuerySnapshot } from '@google-cloud/firestore';
import { MediaService } from 'src/app/core/services/media/media.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable()
export class MediaEffects {
    private mediaService: MediaService = inject(MediaService);
    private storage: AngularFireStorage = inject(AngularFireStorage);

    constructor(
        private actions$: Actions,
    ) {}

    startLoadingMedia$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(mediaActions.loadMediaStart),
          switchMap(payload =>
            this.mediaService.fetchMedia().pipe(
              map(data => data),
              catchError(err => of(null))
            )
          ),
          concatMap(data => {
            const response = data as QuerySnapshot;
            const mediaArray: Media[] = [];

            if(response) {
              response.docs.forEach(media => {
                const mediaData = media.data() as Media;
                mediaArray.push(mediaData);
              })
            }

            return [
                mediaActions.loadMediaSuccess({ media: mediaArray}),
            ];
          })
        );
      }, { dispatch: true });

      storeMediaSuccess$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(mediaActions.storeMediaSuccess),
          mergeMap(action =>
            this.mediaService.saveMedia(action.media).pipe(
              // You need to dispatch an action here if needed
              map(() => mediaActions.storeMediaInFirestoreSuccess()),
              catchError(err => {
                console.error('Error saving media to Firestore:', err);
                // Dispatch a failure action on error
                return of(mediaActions.storeMediaFailure({ error: err.message }));
              })
            )
          )
        );
      });

      deleteMedia$ = createEffect(() =>
        this.actions$.pipe(
          ofType(mediaActions.deleteMedia),
          mergeMap(({ mediaId, mediaPath }) => {
            // First, delete from Firebase Storage
            return this.storage.ref(mediaPath).delete().pipe(
              mergeMap(() => 
                this.mediaService.deleteMediaFromFirestore(mediaId).pipe(
                  map(() => mediaActions.deleteMediaSuccess()),
                  catchError((error) => of(mediaActions.deleteMediaFailure({ error })))
                )
              ),
              catchError((error) => of(mediaActions.deleteMediaFailure({ error })))
            );
          })
        )
      );
}