import { Component, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Store } from '@ngrx/store';
import { User } from './core/models/user';
import * as fromStore from './store';
import { TicketCartItem } from './core/models/TicketCartItem';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web';
  authSub$: any;
  meta: Meta = inject(Meta);

  constructor(
    private auth: AngularFireAuth,
    private store: Store<fromStore.GlobalAppState>
  ) {
    this.meta.updateTag(
      {name: 'keywords', content: 'The Comedy Stand, Halifax live comedy club, comedy club, live comedy, standup comedy, halifax nova scotia, nova scotia, ns, comedy shows in Halifax, live comedy Halifax, stand-up comedy Halifax, halifax live entertainment, best live comedy show in Halifax, upcoming comedy events in Halifax, upcoming comedy in Halifax, upcoming comedy shows in Halifax'}
    );
    this.meta.updateTag({ property: 'og:title', content: environment.title });
    this.meta.updateTag({ property: 'og:description', content: 'Enjoy top live comedy performances in Halifax with The Comedy Stand. Check out our event schedule for the best live stand up comedy.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://thecomedystand.com/assets/images/og_share.png' });
  }

  ngOnInit(): void {
    let localUser = localStorage.getItem("comedystand-user") as string;
    if(localUser) {
      let user = JSON.parse(localUser) as User;
      this.store.dispatch(fromStore.userGetDataComplete({user: user}));
    }

    this.authSub$ = this.auth.authState.subscribe(user => {
      if(user) {
        // this.store.dispatch(fromStore.userGetDataStart({uid: user.uid}));
        // this.store.dispatch(fromStore.ticketsGetHistoryStart({uid: user.uid}))
      }
    });

    // Set cart from localStorage
    const localCart = localStorage.getItem("user_cart");
    if(localCart) {
      const cartJson = JSON.parse(localCart);
      cartJson.forEach((cartItem: TicketCartItem) => {
        this.store.dispatch(fromStore.cartAddItem({ticketCartItem: cartItem}));
      })
    }

    // Load App Data
    this.store.dispatch(fromStore.loadAppDataStart());

    // Hydrate ticket state
    // this.store.dispatch(fromStore.cartSetTicketState());
  }

  ngOnDestroy(): void {
    if(this.authSub$) {
      this.authSub$.unsubscribe();
    }
  }
}
