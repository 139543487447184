import { createReducer, on } from "@ngrx/store";
import * as userActions from "../actions/user.actions";
import { User, UserNotification } from "../../core/models/user";

export interface UserState {
    user_data: User | null;
    user_guest: any;
    unread_notifications: number | null,
    authenticating: boolean;
    authenticated: boolean;
    authenticateError: string | null;
    loading: boolean;
    loaded: boolean;
    redirect_url: string;
}

export const initialState: UserState = {
    user_data: null,
    user_guest: null,
    unread_notifications: null,
    authenticating: false,
    authenticated: false,
    authenticateError: null,
    loading: false,
    loaded: false,
    redirect_url: '/',
};

export const userReducer = createReducer(
    initialState,
    on(userActions.userLogInStart, (state, { email, password }) => {  
        return {
            ...state,
            authenticating: true,
            authenticated: false,
            authenticateError: null
        }
    }),
    on(userActions.userLogInComplete, (state, { success, error }) => {
        localStorage.removeItem("guest_user");

        return {
            ...state,
            authenticated: success,
            authenticating: false,
            authenticateError: error
        }
    }),
    on(userActions.userLogOutStart, (state, {}) => {      
        return {
            ...state
        }
    }),
    on(userActions.userLogOutComplete, (state, { success }) => {     
        localStorage.removeItem("comedystand-user");

        return {
            ...state,
            user_data: null,
            authenticated: false,
            authenticating: false,
        }
    }),
    on(userActions.userCreateRecord, (state, {  }) => {
        return {
            ...state
        }
    }),
    on(userActions.userGetDataStart, (state, { uid }) => {     
        return {
            ...state
        }
    }),
    on(userActions.userGetDataComplete, (state, { user }) => {
        localStorage.setItem("comedystand-user", JSON.stringify(user));
        localStorage.setItem("comedystand-uid", JSON.stringify(user.uid));
        let unreadNotifications = null;

        if(user.notifications) {
            unreadNotifications = user.notifications.filter((notification: UserNotification) => notification.status === 'unread');
        }

        return {
            ...state,
            user_data: user,
            user_guest: null,
            unread_notifications: unreadNotifications && unreadNotifications.length > 0 ? unreadNotifications.length : null,
            authenticated: true,
            authenticating: false,
            authenticateError: null
        }
    }),
    on(userActions.userGetDataFailed, (state, {currentUser, error}) => {
        return {
            ...state,
        }
    }),
    on(userActions.userCreateGuest, (state, { ipAddress, sessionId }) => {
        const guestUser = {
            ip_address: ipAddress,
            session_id: sessionId,
            payment_method: "",
            created_at: new Date(),
            last_seen: new Date()
        };
        localStorage.setItem("guest_user", JSON.stringify(guestUser));

        return {
            ...state,
            user_guest: guestUser
        }
    }),
    on(userActions.userRetrieveGuest, (state, { user }) => {
        return {
            ...state,
            cart: user.cart,
            user_guest: {
                ...user,
                last_seen: new Date()
            }
        }
    }),
    on(userActions.userUpdateGuest, (state, { userData }) => {
        return {
            ...state,
            user_guest: {
                ...state.user_guest,
                ...userData
            }
        }
    }),
    on(userActions.userStorePaymentMethod, (state, {payment_method}) => {
        let userState = state.user_data;
        if(userState && !userState.payment_methods) {
            userState.payment_methods = [payment_method];
        } else if (userState && userState.payment_methods) {
            userState.payment_methods.push(payment_method);
        }

        return {
            ...state,
            userData: userState
        }
    }),
    on(userActions.userStoreGuestPaymentMethod, (state, {payment_method}) => {
        return {
            ...state,
            user_guest: {
                ...state.user_guest,
                payment_method: {
                    brand: payment_method.card.brand,
                    exp_month: payment_method.card.exp_month,
                    exp_year: payment_method.card.exp_year,
                    id: payment_method.id,
                    last4: payment_method.card.last4
                }
            }
        }
    }),
    on(userActions.userRemovePaymentMethod, (state, { cardId }) => {
        if(state.user_data?.payment_methods) {
            const methodIndex = state.user_data.payment_methods.findIndex(card => card.id == cardId);
            
            if(methodIndex !== -1) {
                state.user_data.payment_methods.splice(methodIndex, 1);
            }
        }

        return {
            ...state
        }
    }),
);

export const getUser = (state: UserState) => state.user_data;
export const getNotifications = (state: UserState) => state.unread_notifications;