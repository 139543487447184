import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Ticket } from 'src/app/core/models/ticket';
import * as ticketActions from '../actions/ticket.actions';

@Injectable()
export class TicketEffects {
    constructor(
        private actions$: Actions,
        private auth: AngularFireAuth,
        private afs: AngularFirestore
    ) {}

    getTicketsStart$ = createEffect(() => this.actions$.pipe(
        ofType(ticketActions.ticketsGetHistoryStart),
        mergeMap((action) => this.afs.collection("tickets", ref => ref.orderBy("created_at", "desc").where("uid", "==", action.uid)).valueChanges({idField: 'id'}).pipe(
            map(data => {
                let ticketData = data as unknown;
                return ticketActions.ticketsGetHistoryComplete({tickets: ticketData as Ticket[]})
            })
        ))
    ), {dispatch: true});
    
}