import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/core/models/user';

export const userLogInStart = createAction(
    '[User] Log In Start',
    props<{email: string, password: string}>()
);

export const userLogInComplete = createAction(
    '[User] Log In Complete',
    props<{success: boolean, error: string | null}>()
);

export const userLogOutStart = createAction(
    '[User] Log Out Start'
);

export const userLogOutComplete = createAction(
    '[User] Log Out Complete',
    props<{success: boolean}>()
);

export const userRegisterStart = createAction(
    '[User] Register Start',
    props<{email: string, password: string, first_name: string, last_name: string}>()
);

export const userRegisterComplete = createAction(
    '[User] Register Complete',
    props<{success: boolean, error: string | null}>()
);

export const userCreateRecord = createAction(
    '[User] Create User Record',
    props<{data: any, password: string}>()
);

export const userGetDataStart = createAction(
    '[User] Start to Get User Data',
    props<{uid: string}>()
);

export const userGetDataComplete = createAction(
    '[User] Completed Getting User Data',
    props<{user: User}>()
);

export const userGetDataFailed = createAction(
    '[User] Failed to Get User Data',
    props<{currentUser: any, error: any}>()
);

export const userCreateRecordAfterLogIn = createAction(
    '[User] Create user record after log in',
    props<{user: any}>()
)

export const userStorePaymentMethod = createAction(
    '[User] Store New Payment Method',
    props<{payment_method: any}>()
);

export const userStoreGuestPaymentMethod = createAction(
    '[User] Store New Guest Payment Method',
    props<{payment_method: any}>()
);

export const userCreateGuest = createAction(
    '[User] Create Guest User',
    props<{ipAddress: string, sessionId: string}>()
);

export const userRetrieveGuest = createAction(
    '[User] Retrieve Guest User Session',
    props<{user: any}>()
);

export const userUpdateGuestCart = createAction(
    '[User] Update Guest User Cart',
    props<{cart: any, user: any}>()
);

export const userUpdateGuest = createAction(
    '[User] Update Guest User Data',
    props<{userData: any}>()
);

export const userUpdateProfile = createAction(
    '[User] Update User Profile',
    props<{userData: any}>()
);

export const userUpdateProfileComplete = createAction(
    '[User] Updated User Profile'
);

export  const userRemovePaymentMethod = createAction(
    '[User] Remove Payment Method',
    props<{cardId: string, userData: User}>()
)