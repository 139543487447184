import { createAction, props } from '@ngrx/store';
import { Post } from 'src/app/core/models/post';

export const loadBlogPostsStart = createAction(
    '[Posts] Start to Get Blog Posts'
);

export const loadingBlogPostsComplete = createAction(
    '[Posts] Blog Posts Complete',
    props<{posts: Post[]}>()
);