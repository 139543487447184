import { createAction, props } from '@ngrx/store';
import { Event } from 'src/app/core/models/event';

export const loadAdminEventsStart = createAction(
    '[Admin] Start to Load Admin Events'
);

export const loadAdminEventsSuccess = createAction(
    '[Admin] Successfully Loaded Admin Events',
    props<{events: Event[]}>()
);