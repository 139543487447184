import { createAction, props } from '@ngrx/store';
import { Ticket } from 'src/app/core/models/ticket';

export const ticketsGetHistoryStart = createAction(
    '[Tickets] Start to Get Ticket History',
    props<{uid: string}>()
);

export const ticketsGetHistoryComplete = createAction(
    '[Tickets] Ticket History Complete',
    props<{tickets: Ticket[]}>()
);