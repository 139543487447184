import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  afs: AngularFirestore = inject(AngularFirestore);

  fetchUpcomingEvents(): Observable<unknown> {
    return this.afs.collection('events', ref=>ref.where('event_date_timestamp', '>', new Date()).where('published', '==', true))
      .get();
  }

  fetchAllEvents(): Observable<unknown> {
    return this.afs.collection('events', ref=>ref.orderBy('event_date_timestamp', 'asc'))
      .get();
  }
}
