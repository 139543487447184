import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as settingActions from '../actions/settings.actions';
import * as userActions from '../actions/user.actions';
import { UserService } from 'src/app/core/services/user/user.service';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, of } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class SettingsEffects {
    userService: UserService = inject(UserService);
    afAuth: AngularFireAuth = inject(AngularFireAuth);
    functions: AngularFireFunctions = inject(AngularFireFunctions);

    constructor(
        private actions$: Actions
    ) {}

    updateNotificationSettingsStart$ = createEffect(() => this.actions$.pipe(
        ofType(settingActions.settingsUpdateNotificationsStart),
        mergeMap(async (action) => {
            const user = JSON.parse(localStorage.getItem('comedystand-user')!);
            return await this.userService.updateUser(user.uid, {notification_settings: action.notificationSettings}).then(response => {
                return settingActions.settingsUpdateNotificationsComplete()
            });
        }),
    ), {dispatch: true});

    updateProfileSettingsStart$ = createEffect(() => this.actions$.pipe(
        ofType(settingActions.settingsUpdateProfileStart),
        mergeMap(async (action) => {
            const user = JSON.parse(localStorage.getItem('comedystand-user')!);
            return await this.userService.updateUser(user.uid, {
                first_name: action.profileSettings.firstName,
                last_name: action.profileSettings.lastName,
            }).then(response => {
                return settingActions.settingsUpdateProfileComplete()
            });
        }),
    ), {dispatch: true});

    updateUserEmailStart$ = createEffect(() => this.actions$.pipe(
        ofType(settingActions.settingsUpdateUserEmailStart),
        switchMap(action => {
            return from(this.afAuth.currentUser).pipe(
                switchMap(user => {
                    if (user) {
                        return from(user.updateEmail(action.email)).pipe(
                            mergeMap(() => {
                                return [
                                    userActions.userUpdateProfile({userData: {email: action.email}}),
                                    settingActions.settingsUpdateProfileComplete()
                                ]
                            }),
                            catchError(error => of(settingActions.settingsUpdateFormMessage({message: 'There was an error updating your email.'})))
                        );
                    } else {
                        return of(settingActions.settingsUpdateFormMessage({message: 'There was an error updating your email.'}));
                    }
                })
            );
        })
    ), {dispatch: true});

    updateUserPasswordStart$ = createEffect(() => this.actions$.pipe(
        ofType(settingActions.settingsUpdateUserPasswordStart),
        switchMap(action => {
            return from(this.afAuth.currentUser).pipe(
                switchMap(user => {
                    if (user) {
                        const credential = firebase.auth.EmailAuthProvider.credential(user?.email as string, action.currentPassword);
                        return from(user.reauthenticateWithCredential(credential)).pipe(
                            mergeMap(() => {
                                return from(user.updatePassword(action.newPassword)).pipe(
                                    mergeMap(() => {
                                        return [settingActions.settingsUpdateProfileComplete()]
                                    })
                                )
                            }),
                            catchError(error => of(settingActions.settingsUpdateFormMessage({message: 'There was an error updating your password.'})))
                        );
                    } else {
                        return of(settingActions.settingsUpdateFormMessage({message: 'There was an error updating your password.'}));
                    }
                })
            );
        })
    ), {dispatch: true});
}