import { createAction, props } from '@ngrx/store';
import { Token } from '@stripe/stripe-js';
import { StripeCardNumberComponent } from 'ngx-stripe';
import { Card } from 'src/app/core/models/card';

export const cardAddItemStart = createAction(
    '[Card] Start to Add Payment Method',
    props<{card: StripeCardNumberComponent, name: string}>()
);

export const cardTokenCreated = createAction(
    '[Card] Token Successfully Created',
    props<{token: Token}>()
)

export const cardAddItemSuccess = createAction(
    '[Card] Successfully Added Payment Method',
    props<{response: any}>()
);

export const cardAddItemFail = createAction(
    '[Card] Failed to Add Payment Method',
    props<{response: any}>()
);

export const cardRemoveItemStart = createAction(
    '[Card] Start to Remove Payment Method',
    props<{card_id: string}>()
);

export const cardRemoveItemSuccess = createAction(
    '[Card] Successfully Removed Payment Method',
    props<{card_id: string}>()
);

export const cardResetDeleted = createAction(
    '[Card] Reset Payment Method Deleted'
);