import { createReducer, on } from "@ngrx/store";
import * as gigSheetActions from "../actions/gigsheet.actions";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { GigSheet } from "src/app/core/models/gigsheet";
import { Timestamp } from 'firebase/firestore';

export const adapter: EntityAdapter<GigSheet> = createEntityAdapter<GigSheet>({
    sortComparer: false,
    selectId: (gigsheet: GigSheet) => gigsheet.gigsheet_id,
});

export interface GigSheetState extends EntityState<GigSheet> {
    ids: string[];
    entities: { [id: string ]: GigSheet };
    loading: boolean;
    loaded: boolean;
    saving: boolean;
    lastUpdated: Date | null;
}

export const initialState: GigSheetState = {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
    saving: false,
    lastUpdated: null,
};

export const gigSheetsReducer = createReducer(
    initialState,
    on(gigSheetActions.loadGigSheetsStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(gigSheetActions.loadGigSheetsComplete, (state, { gigsheets }) => {
        return adapter.setAll(gigsheets, {
            ...state,
            loaded: true,
            loading: false,
            lastUpdated: new Date(),
        });
    }),
    on(gigSheetActions.storeNewGigSheetStart, (state, { gigsheet }) => {
        return adapter.addOne({
            ...gigsheet,
            event_date_timestamp: Timestamp.fromDate(gigsheet.event_date_timestamp)
        },
        {
            ...state,
            saving: true,
            lastUpdated: new Date(),
        });
    }),
    on(gigSheetActions.storeNewGigSheetSuccess, (state) => {
        return {
            ...state,
            saving: false,
            lastUpdated: new Date(),
        };
    }),
    on(gigSheetActions.addGigsheet, (state, { gigsheet }) => {
        return adapter.addOne(gigsheet, {
            ...state,
            saving: false,
            lastUpdated: new Date(),
        });
    }),
    on(gigSheetActions.updateGigsheetStart, (state) => {
        return {
            ...state,
            saving: true
        };
    }),
    on(gigSheetActions.updateGigsheetSuccess, (state, { gigsheet }) => {
        return adapter.setOne({
            ...gigsheet,
            event_date_timestamp: Timestamp.fromDate(gigsheet.event_date_timestamp)
        },
        {
            ...state,
            saving: false
        });
    }),
);

export const getGigSheetState = (state: GigSheetState) => state;
export const getGigSheetsLoading = (state: GigSheetState) => state.loading;
export const getGigSheetSaving = (state: GigSheetState) => state.saving;
export const {
    selectIds: selectGigSheetIds,
    selectEntities: selectGigSheetEntities,
    selectAll: selectAllGigSheet,
    selectTotal: gigSheetsCount,
  } = adapter.getSelectors();