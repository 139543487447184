import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminFinancialReportsState } from '../reducers/admin.financial.reports.reducers';

export const selectFinancialReportsState = createFeatureSelector<AdminFinancialReportsState>('admin_financial_reports');

export const selectAllFinancialReports = createSelector(selectFinancialReportsState, (state: AdminFinancialReportsState) => state.entities);

export const selectFinancialReportsLoading = createSelector(selectFinancialReportsState, (state: AdminFinancialReportsState) => state.loading);
export const selectFinancialReportsLoaded = createSelector(selectFinancialReportsState, (state: AdminFinancialReportsState) => state.loaded);

export const selectFinancialReportsError = createSelector(selectFinancialReportsState, (state: AdminFinancialReportsState) => state.error);

export const selectRevenueChartData = createSelector(selectFinancialReportsState, (state: AdminFinancialReportsState) => state.revenueChartData);
export const selectExpensesChartData = createSelector(selectFinancialReportsState, (state: AdminFinancialReportsState) => state.expensesChartData);

export const selectAllFinancialReportsArray = createSelector(
    selectFinancialReportsState,
    (state: AdminFinancialReportsState) => Object.values(state.entities) // Convert entities to array
  );