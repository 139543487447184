import { createReducer, on } from "@ngrx/store";
import * as adminFinancesActions from "../actions/admin.finances.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from "luxon";
import { FinancialReports } from "src/app/core/models/financial.reports";

export const adapter: EntityAdapter<FinancialReports> = createEntityAdapter<FinancialReports>({
    sortComparer: false,
});

export interface AdminFinancialReportsState extends EntityState<FinancialReports> {
    loading: boolean;
    loaded: boolean;
    numDays: number;
    revenueChartData: Array<{ name: string; series: Array<{ name: string; value: number }> }>;
    expensesChartData: Array<{ name: string; series: Array<{ name: string; value: number }> }>;
    error: string | null;
}

export const initialState: AdminFinancialReportsState = {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
    revenueChartData: [],
    expensesChartData: [],
    numDays: 30,
    error: null,
};

export const adminFinancialReportsReducer = createReducer(
  initialState,
  on(adminFinancesActions.loadAdminFinancialReportsStart, (state) => ({
      ...state,
      loading: true,
      loaded: false,
      metrics: null,
  })),
  on(adminFinancesActions.loadAdminFinancialReportsSuccess, (state, { reports }) => {
      const revenueData = generateChartData(state.numDays, reports, 'month', 'revenue');
      const expensesData = generateChartData(state.numDays, reports, 'month', 'expenses');

      return adapter.setAll(reports, {
          ...state,
          loaded: true,
          loading: false,
          revenueChartData: revenueData,
          expensesChartData: expensesData,
      });
  }),
  on(adminFinancesActions.loadAdminFinancialReportsFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error: error,
  }))
);

export const getAdminFinancialReportsState = (state: AdminFinancialReportsState) => state;
export const getAdminFinancialReportsLoading = (state: AdminFinancialReportsState) => state.loading;
export const {
    selectIds: selectAdminFinancialReportsIds,
    selectEntities: selectAdminFinancialReportsEntities,
    selectAll: selectAllAdminFinancialReports,
    selectTotal: adminFinancialReportsCount,
  } = adapter.getSelectors();

function generateChartData(
  numDays: number,
  reports: FinancialReports[],
  period: 'day' | 'week' | 'month',
  type: 'revenue' | 'expenses'
): Array<{ name: string; series: Array<{ name: string; value: number }> }> {
  const today = DateTime.fromJSDate(new Date()).startOf('day');
  
  // Generate an array of dates from numDays ago to today
  const dates = [];
  for (let i = 0; i <= numDays; i++) {
    const date = today.minus({ days: i }).startOf('day').toMillis(); // Keep date in millis for consistency
    dates.push({ name: date, series: [] });
  }

  // Group the reports by day for the given type (revenue or expenses)
  const groupedData = groupReportsBy(reports, period, type);

  // Populate the chart data with totals for each day
  return dates.map(dateObj => {
    // Get the value (either revenue or expenses) for this day
    const total = groupedData[dateObj.name] || 0;

    return {
      name: DateTime.fromMillis(dateObj.name).toFormat('MMM dd'), // Convert millis back to formatted string
      series: [
        {
          name: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize 'revenue' or 'expenses'
          value: Math.round((total / 100) * 100) / 100 // Keep 2 decimal places
        }
      ]
    };
  });
}
  
function groupReportsBy(reports: FinancialReports[], period: 'day' | 'week' | 'month', type: 'revenue' | 'expenses') {
  return reports.reduce((acc, report) => {
    const date = DateTime.fromJSDate(report.created_at.toDate());
    let key: number;
    switch (period) {
      case 'day':
        key = date.startOf('day').toMillis();
        break;
      case 'week':
        key = date.startOf('week').toMillis();
        break;
      case 'month':
        key = date.startOf('month').toMillis();
        break;
    }

    // Calculate the total for either 'revenue' or 'expenses'
    let amountTotal = 0;
    if (type === 'revenue') {
      // Sum total by multiplying price * amount_sold for each entry in revenue
      amountTotal = report.revenue.reduce((sum, revenueItem) => {
        return sum + (revenueItem.price * revenueItem.amount_sold);
      }, 0);
    } else if (type === 'expenses') {
      // Sum the amount for each entry in expenses
      amountTotal = report.expenses.reduce((sum, expenseItem) => {
        return sum + expenseItem.amount;
      }, 0);
    }

    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key] += amountTotal;
    return acc;
  }, {} as { [key: number]: number }); // Group by number (milliseconds)
}
  