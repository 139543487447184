import { createReducer, on } from "@ngrx/store";
import * as adminTaskActions from "../actions/admin.task.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Task } from "src/app/core/models/task.model";
import { DateTime } from "luxon";

export const adapter: EntityAdapter<Task> = createEntityAdapter<Task>({
    sortComparer: false
});

export interface AdminTasksState extends EntityState<Task> {
    loading: boolean;
    loaded: boolean;
    error: string | null;
}

export const initialState: AdminTasksState = {
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
    error: null, 
};

export const adminTasksReducer = createReducer(
    initialState,
    on(adminTaskActions.loadTasks, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(adminTaskActions.loadTasksSuccess, (state, { tasks }) => {
        return adapter.setAll(tasks, {
            ...state,
            loaded: true,
            loading: false,
        });
    }),
    on(adminTaskActions.createTask, (state, {}) => {
        console.log('Create Task Reducer');
        return {
            ...state,
            loading: true
        }
    }),
    on(adminTaskActions.createTaskSuccess, (state, { task }) => {
        return adapter.addOne(task, {
          ...state
        });
    }),
    on(adminTaskActions.updateTaskStatusSuccess, (state, { taskId, status }) => 
      adapter.updateOne(
        { id: taskId, changes: { status } }, 
        state
      )
    ),
    on(adminTaskActions.updateTask, (state, { taskId, task }) => 
        adapter.setOne(task, {
            ...state
        })
    )
);

export const getAdminTasksState = (state: AdminTasksState) => state;
export const getAdminTasksLoading = (state: AdminTasksState) => state.loading;
export const {
    selectIds: selectAdminTasksIds,
    selectEntities: selectAdminTasksEntities,
    selectAll: selectAllAdminTasks,
    selectTotal: adminTasksCount,
  } = adapter.getSelectors();  
  