import { EventImage } from "./event_image";
import { TicketType } from "./TicketType";
import { Venue } from "./venue";

export class Event {
    id: string = "";
    available_tickets: number = 0;
    comics: any[] = [];
    created_at: Date = new Date();
    description: string = "";
    event_date: string = "";
    event_date_timestamp: any;
    event_date_converted: any;
    intl_timestamp: any;
    image_featured: EventImage = new EventImage();
    image_poster: EventImage = new EventImage();
    is_we_have_fun: boolean = false;
    price: number = 20;
    slug: string = "";
    gigsheet_id?: string = "";
    related_shows: Event[] = [];
    start_time: string = "";
    tickets_sold: number = 0;
    ticket_tiers: TicketType[] = [];
    check_ins: number = 0;
    title: string = "";
    external_ticket_link: string = "";
    external_tickets: boolean = false;
    featured: boolean = false;
    free_event: boolean = false;
    venue: Venue = new Venue();
    updated_at: Date = new Date();
}