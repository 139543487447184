import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromGigSheets from '../reducers/gigsheet.reducers';

export const getRootGigsheetsState = (state: fromRoot.GlobalAppState) => state.gigsheets;
export const getAllGigSheets = createSelector(getRootGigsheetsState, fromGigSheets.selectAllGigSheet);
export const selectGigSheetsLoading = createSelector(getRootGigsheetsState, fromGigSheets.getGigSheetsLoading);
export const selectGigSheetSaving = createSelector(getRootGigsheetsState, fromGigSheets.getGigSheetSaving);
export const getSingleGigSheet = (id: string) => createSelector(getAllGigSheets, (allGigSheets) => {
    if(allGigSheets) {
        return allGigSheets.filter(gigSheet => {
            return gigSheet.gigsheet_id === id;
        })[0];
    } else {
        return null;
    }
});