import { createReducer, on } from "@ngrx/store";
import * as adminTicketsActions from "../actions/admin.tickets.actions";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Ticket } from "src/app/core/models/ticket";
import { DateTime } from "luxon";

export const adapter: EntityAdapter<Ticket> = createEntityAdapter<Ticket>({
    sortComparer: false,
    selectId: (ticket: Ticket) => ticket.order_number,
});

export interface AdminTicketsState extends EntityState<Ticket> {
    loading: boolean;
    loaded: boolean;
    metrics: {
        bestDay: { date: number, total: number },
        worstDay: { date: number, total: number },
        bestWeek: { date: number, total: number },
        worstWeek: { date: number, total: number },
        bestMonth: { date: number, total: number },
        worstMonth: { date: number, total: number },
        averageSpend: number,
        topVenues: Array<{ venueId: string, venueName: string, totalRevenue: number, ticketsSold: number }>,
    } | null;
    numDays: number;
    revenueData: Array<{ name: string; value: number }>;
    ticketData: Array<{ name: string; value: number }>;
    error: string | null;
}

export const initialState: AdminTicketsState = {
    ids: [],
    entities: {},
    metrics: null,
    loading: true,
    loaded: false,
    revenueData: [],
    ticketData: [],
    numDays: 7,
    error: null, 
};

export const adminTicketsReducer = createReducer(
    initialState,
    on(adminTicketsActions.loadAdminOnlineSalesStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
            metris: null,
        }
    }),
    on(adminTicketsActions.loadAdminOnlineSalesSuccess, (state, { tickets }) => {
        const calculatedMetrics = calculateSalesMetrics(tickets);
        const revenueData = generateChartData(state.numDays, tickets);
        const ticketData = generateTicketData(state.numDays, tickets);
        const topVenues = calculateTopProfitableVenues(tickets);

        return adapter.setAll(tickets, {
            ...state,
            loaded: true,
            loading: false,
            revenueData: revenueData,
            ticketData: ticketData,
            metrics: {
                bestDay: calculatedMetrics.bestDay,
                worstDay: calculatedMetrics.worstDay,
                bestWeek: calculatedMetrics.bestWeek,
                worstWeek: calculatedMetrics.worstWeek,
                bestMonth: calculatedMetrics.bestMonth,
                worstMonth: calculatedMetrics.worstMonth,
                averageSpend: calculatedMetrics.averageSpend,
                topVenues: topVenues
            }
        });
    }),
    on(adminTicketsActions.changeAdminTicketChartRange, (state, { days }) => {
      const ticketsArray = adapter.getSelectors().selectAll(state);
      const revenueData = generateChartData(days, ticketsArray);
      const ticketData = generateTicketData(days, ticketsArray);

      return {
        ...state,
        revenueData: revenueData,
        ticketData: ticketData
      }
    })
);

export const getAdminTicketsState = (state: AdminTicketsState) => state;
export const getAdminTicketsLoading = (state: AdminTicketsState) => state.loading;
export const getAdminTicketMetrics = (state: AdminTicketsState) => state.metrics;
export const {
    selectIds: selectAdminTicketsIds,
    selectEntities: selectAdminTicketEntities,
    selectAll: selectAllAdminTickets,
    selectTotal: adminTicketsCount,
  } = adapter.getSelectors();

function calculateSalesMetrics(tickets: Ticket[]) {
    const groupedByDay = groupTicketsBy(tickets, 'day');
    const groupedByWeek = groupTicketsBy(tickets, 'week');
    const groupedByMonth = groupTicketsBy(tickets, 'month');
    const averageSpend = calculateAverageSpendPerCustomer(tickets);

    return {
        bestDay: getBestOrWorst(groupedByDay, 'best'),
        worstDay: getBestOrWorst(groupedByDay, 'worst'),
        bestWeek: getBestOrWorst(groupedByWeek, 'best'),
        worstWeek: getBestOrWorst(groupedByWeek, 'worst'),
        bestMonth: getBestOrWorst(groupedByMonth, 'best'),
        worstMonth: getBestOrWorst(groupedByMonth, 'worst'),
        averageSpend: averageSpend
    };
}

function groupTicketsBy(tickets: Ticket[], period: 'day' | 'week' | 'month') {
    return tickets.reduce((acc, ticket) => {
      const date = DateTime.fromJSDate(ticket.created_at.toDate());
      let key: number;
      switch (period) {
        case 'day':
          key = date.startOf('day').toMillis();
          break;
        case 'week':
          key = date.startOf('week').toMillis();
          break;
        case 'month':
          key = date.startOf('month').toMillis();
          break;
      }
  
      const amountTotal = parseFloat(ticket.amount_total as any) || 0;
  
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += amountTotal;
      return acc;
    }, {} as { [key: string]: number });
}

function getBestOrWorst(groupedData: { [key: string]: number }, type: 'best' | 'worst') {
    let resultKey: string | null = null;
    let resultValue = type === 'best' ? -Infinity : Infinity;
  
    Object.entries(groupedData).forEach(([key, value]) => {
      if (type === 'best' && value > resultValue) {
        resultKey = key;
        resultValue = value;
      } else if (type === 'worst' && value < resultValue && value > 0) {
        resultKey = key;
        resultValue = value;
      }
    });
  
    return { date: resultKey ? Number(resultKey) : 0, total: resultValue };
}

function generateChartData(numDays: number, tickets: Ticket[]): Array<{ name: string; value: number }> {
    const today = DateTime.fromJSDate(new Date()).startOf('day');
    const startDate = today.minus({ days: 120 });
    
    // Generate an array of dates from 30 days ago to today
    const dates = [];
    for (let i = 0; i <= numDays; i++) {
      const date = today.minus({ days: i }).toFormat('MMM dd');
      dates.push({ name: date, value: 0 });
    }
  
    dates.reverse();
    
    // Group the tickets by day
    const groupedData = groupTicketsBy(tickets, 'day');
  
    // Populate the chart data with ticket totals for each day
    return dates.map(dateObj => {
      const date = DateTime.fromFormat(dateObj.name, 'MMM dd').toMillis();
      const revenue = groupedData[date] || 0;
      const formattedRevenue = Math.round((revenue / 100) * 100) / 100;

      return {
        name: dateObj.name,
        value: formattedRevenue,
      };
    });
}  

function generateTicketData(numDays: number, tickets: Ticket[]): Array<{ name: string; value: number }> {
  const today = DateTime.fromJSDate(new Date()).startOf('day');
  
  // Generate an array of dates from numDays ago to today
  const dates = [];
  for (let i = 0; i <= numDays; i++) {
      const date = today.minus({ days: i }).toFormat('MMM dd');
      dates.push({ name: date, value: 0 });
  }

  dates.reverse();

  // Group the tickets by day and sum the quantity
  const groupedData = tickets.reduce((acc, ticket) => {
      const date = DateTime.fromJSDate(ticket.created_at.toDate()).startOf('day').toMillis();
      const quantity = ticket.quantity || 0; // Get the ticket quantity
      
      if (!acc[date]) {
          acc[date] = 0;
      }
      acc[date] += quantity; // Sum the quantity
      return acc;
  }, {} as { [key: number]: number });

  // Populate the ticket data with totals for each day
  return dates.map(dateObj => {
      const date = DateTime.fromFormat(dateObj.name, 'MMM dd').toMillis();
      const totalQuantity = groupedData[date] || 0; // Get the total quantity for the day

      return {
          name: dateObj.name,
          value: totalQuantity, // Use the total quantity as the value
      };
  });
}

function calculateAverageSpendPerCustomer(tickets: Ticket[]): number {
  // Group tickets by customer email
  const customerSpending = tickets.reduce((acc, ticket) => {
      let email = '';
      let totalSpent = parseFloat(ticket.amount_total as any) || 0;
      totalSpent = totalSpent / 100;

      if(ticket.user && ticket.user.email) {
        email = ticket.user.email;
      }

      if (email === '') {
        return acc;
      }

      if (!acc[email]) {
          acc[email] = 0;
      }
      acc[email] += totalSpent;
      return acc;
  }, {} as { [email: string]: number });

  const totalRevenue = Object.values(customerSpending).reduce((acc, customerTotal) => acc + customerTotal, 0);
  const uniqueCustomers = Object.keys(customerSpending).length;
  return uniqueCustomers > 0 ? totalRevenue / uniqueCustomers : 0;
}

function calculateTopProfitableVenues(tickets: Ticket[]): Array<{ venueId: string, venueName: string, totalRevenue: number, ticketsSold: number }> {
  // Group tickets by venue ID and venue name, and track total revenue and tickets sold
  const venueProfits = tickets.reduce((acc, ticket) => {
    const venueId = ticket.event_details?.venue?.id;
    const venueName = ticket.event_details?.venue?.title;
    const totalSpent = parseFloat(ticket.amount_total as any) / 100 || 0; // Convert amount_total from cents to dollars
    const ticketsSold = ticket.quantity || 1; // Use the quantity field, defaulting to 1 if missing

    if (!venueId || !venueName) {
      return acc; // Skip tickets without a venue ID or name
    }

    if (!acc[venueId]) {
      acc[venueId] = { venueName, totalRevenue: 0, ticketsSold: 0 };
    }
    acc[venueId].totalRevenue += totalSpent; // Sum the revenue for each venue
    acc[venueId].ticketsSold += ticketsSold; // Sum the quantity of tickets sold for each venue
    return acc;
  }, {} as { [venueId: string]: { venueName: string; totalRevenue: number; ticketsSold: number } });

  // Convert the venueProfits object into an array and sort by totalRevenue
  const sortedVenues = Object.entries(venueProfits)
    .map(([venueId, data]) => ({ venueId, venueName: data.venueName, totalRevenue: data.totalRevenue, ticketsSold: data.ticketsSold }))
    .sort((a, b) => b.totalRevenue - a.totalRevenue); // Sort by revenue in descending order

  return sortedVenues;
}

  