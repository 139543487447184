import { TicketType } from './TicketType';
import { Event } from './event';

export class TicketCartItem {
    quantity: number = 0;
    ticket_type: TicketType = new TicketType();
    event: Event = new Event();
}

export class TicketItem {
    event: Event = new Event();
    total_quantity: number = 0;
    ticket_types: { [id: string]: TicketCartType } = {};
}

export class TicketCartType {
    quantity: number = 0;
    ticket_type: TicketType = new TicketType();
}

export class CartTotals {
    total: number = 0;
    subtotal: number = 0;
    fees: number = 0;
    taxes: number = 0;
    discount: number = 0;
}