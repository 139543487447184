import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Firestore, collectionData, doc, updateDoc, collection, addDoc } from '@angular/fire/firestore';
import { from, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as TaskActions from '../actions/admin.task.actions';
import { Task, TaskPriority, TaskStatus } from 'src/app/core/models/task.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class AdminTaskEffects {
  constructor(
    private actions$: Actions,
    private firestore: AngularFirestore
  ) {}

  loadTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadTasks),
      switchMap(() => {
        return this.firestore.collection<Task>('tasks', ref=>ref.orderBy('deadline', 'asc')).snapshotChanges().pipe(
          map(actions => {
            const tasks: Task[] = actions.map(a => {
              const data = a.payload.doc.data() as Task;
              const id = a.payload.doc.id;
              return {
                id,
                ...data,
              };
            });
            return TaskActions.loadTasksSuccess({ tasks });
          }),
          catchError(error => of(TaskActions.loadTasksFailure({ error: error.message })))
        );
      })
    )
  );

  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.createTask),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      switchMap(({ task }) => {
        return from(
          this.firestore.collection('tasks').add({
            ...task,
            created_at: new Date(),
            updated_at: new Date()
          }).then(ref => {
            console.log('Document created with ID:', ref.id);
            // Update the task with the new ID field
            return this.firestore.collection('tasks').doc(ref.id).update({
              id: ref.id
            }).then(() => {
              return TaskActions.createTaskSuccess({ task: { ...task, id: ref.id } });
            });
          })
        ).pipe(
          catchError(error => of(TaskActions.createTaskFailure({ error: error.message })))
        );
      })
    )
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTask),
      mergeMap(({ task }) => {
        return from(
          this.firestore.collection('tasks').doc(task.id).set({
            ...task,
            updated_at: new Date()
          }, {merge: true})
        ).pipe(
          map(() => TaskActions.updateTaskSuccess()),
          catchError((error) => of(TaskActions.updateTaskFailure({ error: error.message })))
        );
      })
    )
  );

  updateTaskStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTaskStatus),
      mergeMap(({ taskId, status }) => {
        return this.firestore.doc(`tasks/${taskId}`).update({ status: status, updated_at: new Date() }).then(() => {
          return TaskActions.updateTaskStatusSuccess({ taskId, status });
        }).catch(error => {
          return TaskActions.updateTaskStatusFailure({ error: error.message });
        });
      })
    )
  );  
}