import { createAction, props } from '@ngrx/store';
import { Media } from 'src/app/core/models/media';

export const loadMediaStart = createAction(
    '[Media] Start to Load Media'
);

export const loadMediaSuccess = createAction(
    '[Media] Successfully Loaded Media',
    props<{media: Media[]}>()
);

export const storeMediaStart = createAction(
    '[Media] Start to Store Media'
);

export const storeMediaSuccess = createAction(
    '[Media] Successfully Stored Media',
    props<{media: Media}>()
);

export const storeMediaInFirestoreSuccess = createAction(
    '[Media] Successfully Saved Media In Firestore'
);

export const storeMediaFailure = createAction(
    '[Media] Failed to Save Media In Firestore',
    props<{error: string}>()
);

export const deleteMedia = createAction(
    '[Media] Delete Media',
    props<{ mediaId: string; mediaPath: string }>()
);

export const deleteMediaSuccess = createAction(
    '[Media] Delete Media Success'
);

export const deleteMediaFailure = createAction(
    '[Media] Delete Media Failure',
    props<{ error: any }>()
);