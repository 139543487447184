import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromAdminEvents from '../reducers/admin.event.reducers';
import { Event } from 'src/app/core/models/event';
import { DateTime } from 'luxon';

export const getRootAdminEventState = (state: fromRoot.GlobalAppState) => state.admin_events;
export const selectAllAdminEvents = createSelector(getRootAdminEventState, fromAdminEvents.selectAdminEventEntities);
export const selectAdminEventsLoading = createSelector(getRootAdminEventState, fromAdminEvents.getAdminEventsLoading);
export const selectAdminEventSaving = createSelector(getRootAdminEventState, fromAdminEvents.getAdminEventsSaving);
export const selectAdminEventsArray = (upcoming: boolean = true) => createSelector(selectAllAdminEvents, (allEvents) => {
    const eventsArray: Event[] = [];

    if(allEvents && upcoming) {
        Object.keys(allEvents).forEach(key => {
            if(DateTime.fromSeconds(allEvents[key]?.event_date_timestamp.seconds) >= DateTime.fromJSDate(new Date())) {
                eventsArray.push(allEvents[key]!);
            }
        });
    } else if(allEvents && !upcoming) {
        Object.keys(allEvents).forEach(key => {
            if(DateTime.fromSeconds(allEvents[key]?.event_date_timestamp.seconds) <= DateTime.fromJSDate(new Date())) {
                eventsArray.push(allEvents[key]!);
            }
        });
    }

    return eventsArray;
});