import {TicketMap} from "./TicketMap";

/**
 * @param {string} id
 * @param {string} title
 * @param {any} created_at
 * @param {string} description
 * @param {boolean} dynamic
 * @param {TicketMap[]} dynamic_map
 * @param {Boolean} vip
 * @param {string} name
 * @param {number} price
 * @param {TicketMap[]} ticket_tiers
 * @param {any} updated_at
 */
export class TicketType {
    id = "";
    title = "";
    created_at: any = new Date();
    description = "";
    dynamic = false;
    dynamic_map: TicketMap[] = [];
    vip = false;
    name = "";
    price = 0;
    ticket_tiers: TicketMap[] = [];
    updated_at: any = new Date();
}
