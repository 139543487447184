import { createAction, props } from '@ngrx/store';
import { FinancialReports } from 'src/app/core/models/financial.reports';

export const loadAdminFinancialReportsStart = createAction(
    '[Admin] Start to Load Admin Financial Reports'
);

export const loadAdminFinancialReportsSuccess = createAction(
    '[Admin] Successfully Loaded Admin Financial Reports',
    props<{reports: FinancialReports[]}>()
);

export const loadAdminFinancialReportsFailure = createAction(
    '[Ticket] Load Financial Reports Failure',
    props<{ error: any }>()
);