import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminTicketsState } from '../reducers/admin.ticket.reducers';
import { Ticket } from 'src/app/core/models/ticket';
import { DateTime } from 'luxon';

export const selectTicketState = createFeatureSelector<AdminTicketsState>('admin_tickets');

export const selectAllTickets = createSelector(selectTicketState, (state: AdminTicketsState) => state.entities);

export const selectTicketsLoading = createSelector(selectTicketState, (state: AdminTicketsState) => state.loading);

export const selectTicketsError = createSelector(selectTicketState, (state: AdminTicketsState) => state.error);

export const selectTicketMetrics = createSelector(selectTicketState, (state: AdminTicketsState) => state.metrics);

export const selectRevenueData = createSelector(selectTicketState, (state: AdminTicketsState) => state.revenueData);

export const selectTicketData = createSelector(selectTicketState, (state: AdminTicketsState) => state.ticketData);

export const selectAreTicketsLoaded = createSelector(
    selectAllTickets,
    (tickets) => Object.keys(tickets).length > 0
);

export const selectAllTicketsArray = createSelector(
    selectTicketState,
    (state: AdminTicketsState) => Object.values(state.entities).filter((ticket): ticket is Ticket => !!ticket)
);

export const selectTicketsByDateRange = (days: number) => createSelector(
    selectAllTicketsArray,
    (tickets: Ticket[]) => {
        const targetDate = DateTime.fromJSDate(new Date()).minus({ days }).startOf('day');

        return tickets.filter(ticket => {
        if (ticket.created_at) {
            const ticketDate = DateTime.fromJSDate(ticket.created_at.toDate());
            return ticketDate >= targetDate;
        }
        return false;
        }).sort((a, b) => {
            // Sort tickets by created_at in descending order
            const dateA = DateTime.fromJSDate(a.created_at.toDate());
            const dateB = DateTime.fromJSDate(b.created_at.toDate());
            return dateB.toMillis() - dateA.toMillis(); // Sort in descending order
          });;
    }
);

export const selectTicketsByVenueId = (venueId: string) => createSelector(
    selectAllTicketsArray,
    (tickets: Ticket[]) => {
      return tickets
        .filter(ticket => ticket.event_details?.venue?.id === venueId)
        .sort((a, b) => {
          // Sort by created_at in descending order
          const dateA = DateTime.fromJSDate(a.created_at.toDate());
          const dateB = DateTime.fromJSDate(b.created_at.toDate());
          return dateB.toMillis() - dateA.toMillis(); // Sort in descending order (most recent first)
        });
    }
);
