import { createAction, props } from '@ngrx/store';

export const settingsLoadStart = createAction(
    '[Settings] Start to Load Settings',
    props<{uid: string}>()
);

export const settingsLoadComplete = createAction(
    '[Settings] Load Settings Complete',
    props<{settings: any}>()
);

export const loadUserSettingsComplete = createAction(
    '[Settings] Load User Settings Complete',
    props<{notificationSettings: any}>()
);

export const settingsUpdateNotificationsStart = createAction(
    '[Settings] Start to Update Notification Settings',
    props<{notificationSettings: any}>()
);

export const settingsUpdateNotificationsComplete = createAction(
    '[Settings] Completed Updating Notification Settings'
);

export const settingsUpdateProfileStart = createAction(
    '[Settings] Start to Update Profile Settings',
    props<{profileSettings: any}>()
);

export const settingsUpdateProfileComplete = createAction(
    '[Settings] Completed Updating Profile Settings'
);

export const settingsUpdateFormMessage = createAction(
    '[Settings] Update Settings Form Message',
    props<{message: string}>()
);

export const settingsUpdateUserEmailStart = createAction(
    '[Settings] Update User Email',
    props<{email: string, password: string}>()
);

export const settingsUpdateUserPasswordStart = createAction(
    '[Settings] Update User Password',
    props<{currentPassword: string, newPassword: string}>()
);

export const settingsSetSaving = createAction(
    '[Settings] Set Saving',
    props<{saving: boolean}>()
);

export const settingsSetDialogSaving = createAction(
    '[Settings] Set Dialog Saving',
    props<{saving: boolean}>()
);