import { createAction, props } from '@ngrx/store';

export const loadAppDataStart = createAction(
    '[App] Start to Load App Data'
);

export const toggleAdminSidebar = createAction(
    '[App] Toggle Admin Sidebar'
);

export const setAdminSidebar = createAction(
    '[App] Set Admin Sidebar'
);