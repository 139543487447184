import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Ticket } from 'src/app/core/models/ticket';
import * as adminFinancesActions from '../actions/admin.finances.actions';
import { of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FinancialReports } from 'src/app/core/models/financial.reports';

@Injectable()
export class AdminFinancialReportsEffects {
    actualDate: Date = new Date();
    private afs: AngularFirestore = inject(AngularFirestore);

    constructor(
        private actions$: Actions,
    ) {}

    startGettingFinancialReports$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(adminFinancesActions.loadAdminFinancialReportsStart),
          mergeMap(payload =>
            this.afs.collection<FinancialReports>('event_finances').get().pipe(
              map((querySnapshot) => {
                const reports = querySnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                } as FinancialReports));
                return adminFinancesActions.loadAdminFinancialReportsSuccess({ reports });
              }),
              catchError((error) => of(adminFinancesActions.loadAdminFinancialReportsFailure({ error })))
            )
          )
        );
      }, { dispatch: true });
}