import { createAction, props } from '@ngrx/store';
import { Event } from 'src/app/core/models/event';

export const upcomingEventsStart = createAction(
    '[Events] Start to Get Upcoming Events'
);

export const upcomingEventsComplete = createAction(
    '[Events] Upcoming Events Complete',
    props<{events: Event[]}>()
);