import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromBlog from '../reducers/blog.reducers';

export const getRootBlogState = (state: fromRoot.GlobalAppState) => state.blog;
export const getAllBlogPosts = createSelector(getRootBlogState, fromBlog.getBlogPosts);
export const getSingleBlogPost = (slug: string) => createSelector(getAllBlogPosts, (allPosts) => {
    if(allPosts) {
        return allPosts.filter(post => {
            return post.slug === slug;
        })
    } else {
        return [];
    }
});