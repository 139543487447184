import { createAction, props } from '@ngrx/store';
import { GigSheet } from 'src/app/core/models/gigsheet';

export const loadGigSheetsStart = createAction(
    '[Gig Sheets] Start to Get Gig Sheets'
);

export const loadGigSheetsComplete = createAction(
    '[Gig Sheets] Loading Gig Sheets Complete',
    props<{gigsheets: GigSheet[]}>()
);

export const storeNewGigSheetStart = createAction(
    '[Gig Sheets] Start to Store New Gig Sheet',
    props<{gigsheet: GigSheet}>()
);

export const storeNewGigSheetSuccess = createAction(
    '[Gig Sheets] Successfully Stored New Gig Sheet'
);

export const addGigsheet = createAction(
    '[Gig Sheets] Add Gig Sheet To State',
    props<{gigsheet: GigSheet}>()
);

export const updateGigsheetStart = createAction(
    '[Gig Sheets] Start To Update Gig Sheet'
);

export const updateGigsheetSuccess = createAction(
    '[Gig Sheets] Successfully Updated Gig Sheet',
    props<{gigsheet: GigSheet}>()
);