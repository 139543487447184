import { createReducer, on } from "@ngrx/store";
import * as ticketActions from "../actions/ticket.actions";
import { Ticket } from "../../core/models/ticket";

export interface TicketState {
    ticket_data: Ticket[] | null;
    loading: boolean;
    loaded: boolean;
}

export const initialState: TicketState = {
    ticket_data: null,
    loading: true,
    loaded: false
};

export const ticketReducer = createReducer(
    initialState,
    on(ticketActions.ticketsGetHistoryStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(ticketActions.ticketsGetHistoryComplete, (state, { tickets }) => {
        return {
            ...state,
            ticket_data: tickets,
            loading: false,
            loaded: true
        }
    })
);

export const getTicketState = (state: TicketState) => state;
export const getTickets = (state: TicketState) => state.ticket_data;
export const getTicketsLoading = (state: TicketState) => state.loading;