import { createReducer, on } from "@ngrx/store";
import * as settingActions from "../actions/settings.actions";

export interface SettingsState {
    notification_settings: any;
    formMessage: string;
    loading: boolean;
    loaded: boolean;
    saving: boolean;
    dialogSaving: boolean;
}

export const initialState: SettingsState = {
    notification_settings: null,
    formMessage: '',
    loading: true,
    loaded: false,
    saving: false,
    dialogSaving: false
};

export const settingsReducer = createReducer(
    initialState,
    on(settingActions.settingsLoadStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(settingActions.loadUserSettingsComplete, (state, { notificationSettings }) => {
        return {
            ...state,
            notification_settings: notificationSettings,
            loading: false,
            loaded: true
        }
    }),
    on(settingActions.settingsUpdateNotificationsStart, (state, { notificationSettings }) => {
        return {
            ...state,
            notification_settings: notificationSettings,
            saving: true
        }
    }),
    on(settingActions.settingsUpdateNotificationsComplete, (state) => {
        return {
            ...state,
            saving: false
        }
    }),
    on(settingActions.settingsUpdateProfileStart, (state) => {
        return {
            ...state,
            saving: true
        }
    }),
    on(settingActions.settingsUpdateProfileComplete, (state) => {
        return {
            ...state,
            saving: false
        }
    }),
    on(settingActions.settingsUpdateFormMessage, (state, { message }) => {
        return {
            ...state,
            formMessage: message,
            saving: false
        }
    }),
    on(settingActions.settingsUpdateUserEmailStart, (state, { email, password }) => {
        return {
            ...state,
            saving: true
        }
    }),
    on(settingActions.settingsUpdateUserPasswordStart, (state, { currentPassword, newPassword }) => {
        return {
            ...state,
            saving: true
        }
    }),
    on(settingActions.settingsSetSaving, (state, { saving }) => {
        return {
            ...state,
            saving: saving
        }
    }),
    on(settingActions.settingsSetDialogSaving, (state, { saving }) => {
        return {
            ...state,
            dialogSaving: saving
        }
    }),
);

export const getSettingsState = (state: SettingsState) => state;
export const getSettingsLoading = (state: SettingsState) => state.loading;
export const getNotificationSettings = (state: SettingsState) => state.notification_settings;