import { Injectable, inject } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Event } from 'src/app/core/models/event';
import * as gigSheetActions from '../actions/gigsheet.actions';
import { GigSheetsService } from 'src/app/core/services/gigsheets/gigsheets.service';
import { of } from 'rxjs';
import { DocumentSnapshot, QuerySnapshot } from '@google-cloud/firestore';
import { GigSheet } from 'src/app/core/models/gigsheet';

@Injectable()
export class GigSheetEffects {
    actualDate: Date = new Date();
    private gigSheetService: GigSheetsService = inject(GigSheetsService);

    constructor(
        private actions$: Actions,
    ) {}

    startGettingGigSheets$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(gigSheetActions.loadGigSheetsStart),
          switchMap(payload =>
            this.gigSheetService.fetchAllGigSheets().pipe(
              map(data => data),
              catchError(err => of(null))
            )
          ),
          concatMap(data => {
            const response = data as QuerySnapshot;
            const gigsheets: GigSheet[] = [];

            if(response) {
              response.docs.forEach(gigsheet => {
                const gigsheetData = gigsheet.data() as GigSheet;
                gigsheets.push(gigsheetData);
              })
            }

            return [
                gigSheetActions.loadGigSheetsComplete({ gigsheets: gigsheets}),
            ];
          })
        );
      }, { dispatch: true });

    storeNewGigsheetStart$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(gigSheetActions.storeNewGigSheetStart),
          tap(action => {
            console.log("ACTION", action);
          }),
          switchMap(action => {
            return this.gigSheetService.storeNewGigSheet(action.gigsheet).pipe(
              tap(response => {
                console.log("New Gig sheet response:", response);
              })
            )
          }),
          // concatMap(data => {
          //   const response = data as QuerySnapshot;
          //   const gigsheets: GigSheet[] = [];

          //   response.docs.forEach(gigsheet => {
          //       const gigsheetData = gigsheet.data() as GigSheet;
          //       gigsheets.push(gigsheetData);
          //   })

          //   return [
          //       gigSheetActions.loadGigSheetsComplete({ gigsheets: gigsheets}),
          //   ];
          // })
        );
      }, { dispatch: false });
}