import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  afs: AngularFirestore = inject(AngularFirestore);

  updateUser(uid: string, data: any): Promise<void> {
    return this.afs.collection('users').doc(uid).set({
      ...data,
      updated_at: new Date()
    }, { merge: true });
  }
}
