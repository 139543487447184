import { createFeatureSelector, createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromCart from '../reducers/cart.reducers';
import { Event } from 'src/app/core/models/event';
import { TicketCartItem } from 'functions/src/models/TicketCartItem';

export const selectCartState = createFeatureSelector<fromCart.CartState>('cart');
export const selectCart = createSelector(selectCartState, fromCart.getCartState);

export const selectEventTickets = (event: Event) => createSelector(selectCart, (cart) => {
  let eventCart = cart.cart_items.filter(cartEvent => {
    let tickets = event.ticket_tiers.filter(ticket => ticket.id === cartEvent.ticket_type.id);
    return cartEvent.event.id === event.id && tickets.length > 0;
  })

  let response: TicketCartItem | null = (eventCart.length > 0) ? eventCart[0] : null
  return response;
});

export const selectCartTickets = () => createSelector(selectCart, (cart) => {
  return cart.tickets;
});

export const selectEventTicketTypeQty = (eventId: string, ticketTypeId: string) => createSelector(selectCart, (cart) => {
  if(cart.tickets[eventId] && cart.tickets[eventId].ticket_types[ticketTypeId]) {
    return cart.tickets[eventId].ticket_types[ticketTypeId].quantity;
  } else {
    return 0;
  }
});

export const selectCartCount = () => createSelector(selectCart, (cart) => {
  return cart.ticket_count > 0 ? cart.ticket_count : null;
});

export const selectCartTotals = () => createSelector(selectCart, (cart) => {
  return cart.totals;
});