import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as cardActions from '../actions/card.actions';
import * as userActions from '../actions/user.actions';
import { Store } from '@ngrx/store';
import { StripeService } from 'ngx-stripe';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { of } from 'rxjs';

@Injectable()
export class CardEffects {
    stripeService: StripeService = inject(StripeService);
    functions: AngularFireFunctions = inject(AngularFireFunctions);

    constructor(
        private actions$: Actions,
        private store: Store
      ) {}
    
    cardAddItemStart$ = createEffect(() => this.actions$.pipe(
        ofType(cardActions.cardAddItemStart),
        switchMap(action => {
            return this.stripeService.createToken(action.card.element, { name: action.name }).pipe(
                map(result => {
                    if (result.token) {
                        return cardActions.cardTokenCreated({ token: result.token });
                    } else {
                        return cardActions.cardAddItemFail({ response: "Please enter valid card information." });
                    }
                }),
                catchError(err => {
                    return of(cardActions.cardAddItemFail({response: 'Your card could not be saved at this time.'}));
                })
            );
        }),
        catchError((err) => {
            return of(cardActions.cardAddItemFail({response: 'Your card could not be saved at this time.'}));
        })
    ), {dispatch: true});

    cardTokenSaved$ = createEffect(() => this.actions$.pipe(
        ofType(cardActions.cardTokenCreated),
        mergeMap(action => {
            const fun = this.functions.httpsCallable('stripeCreatePaymentMethod');
            return fun({source: action.token.id}).pipe(
                mergeMap(response => {
                    return [
                        cardActions.cardAddItemSuccess({response: response}),
                        userActions.userStorePaymentMethod({payment_method: {
                            exp_month: response.card.exp_month,
                            brand: response.card.brand,
                            exp_year: response.card.exp_year,
                            id: response.id,
                            last4: response.card.last4
                        }})
                    ];
                }),
                catchError(error => {
                    return of(cardActions.cardAddItemFail({response: 'Your card could not be saved at this time.'}));
                })
            )
        })
    ))

    cardRemoveItemStart$ = createEffect(() => this.actions$.pipe(
        ofType(cardActions.cardRemoveItemStart),
        mergeMap(action => {
            const fun = this.functions.httpsCallable('stripeRemovePaymentMethod');
            return fun({source: action.card_id}).pipe(
                mergeMap(response => {
                    const user = JSON.parse(localStorage.getItem('comedystand-user')!);
                    return [
                        cardActions.cardRemoveItemSuccess({card_id: response.id}),
                        userActions.userRemovePaymentMethod({cardId: action.card_id, userData: user})
                    ];
                })
            )
        })
    ))
}