import { TicketMap } from './TicketMap';

export class TicketType {
    id: string = "";
    title: string = "";
    created_at: any = new Date();
    description: string = "";
    dynamic: boolean = false;
    dynamic_map: TicketMap[] = [];
    vip: boolean = false;
    name: string = "";
    price: number = 0;
    ticket_tiers: TicketMap[] = [];
    updated_at: any = new Date();
}