import { createAction, props } from '@ngrx/store';
import { Task, TaskStatus } from '../../core/models/task.model';

export const loadTasks = createAction('[Task] Load Tasks');
export const loadTasksSuccess = createAction('[Task] Load Tasks Success', props<{ tasks: Task[] }>());
export const loadTasksFailure = createAction('[Task] Load Tasks Failure', props<{ error: string }>());

export const createTask = createAction('[Task] Create Task', props<{ task: Task }>());
export const createTaskSuccess = createAction('[Task] Create Task Success', props<{ task: Task }>());
export const createTaskFailure = createAction('[Task] Create Task Failure', props<{ error: string }>());

export const updateTask = createAction('[Task] Update Task', props<{ taskId: string, task: Task }>());
export const updateTaskSuccess = createAction('[Task] Update Task Success');
export const updateTaskFailure = createAction('[Task] Update Task Failure', props<{ error: string }>());

export const updateTaskStatus = createAction('[Task] Update Task Status', props<{ taskId: string, status: TaskStatus }>());
export const updateTaskStatusSuccess = createAction('[Task] Update Task Status Success', props<{ taskId: string, status: 'To Do' | 'In Progress' | 'Done' }>());
export const updateTaskStatusFailure = createAction('[Task] Update Task Status Failure', props<{ error: string }>());
