import { createReducer, on } from "@ngrx/store";
import * as appActions from "../actions/app.actions";

export interface AppState {
    sidebarExpanded: boolean;
}

export const initialState: AppState = {
    sidebarExpanded: false
};

export const appReducer = createReducer(
    initialState,
    on(appActions.toggleAdminSidebar, (state) => {
        const newState = !state.sidebarExpanded;
        localStorage.setItem('tcs-admin-sidebar', `${newState}`);

        return {
            ...state,
            sidebarExpanded: !state.sidebarExpanded
        }
    }),
    on(appActions.setAdminSidebar, (state) => {
        const sidebarState = localStorage.getItem('tcs-admin-sidebar');
        let newState = true;

        if(!sidebarState || sidebarState === 'true') {
            newState = true;
        } else if(sidebarState && sidebarState === 'false') {
            newState = false;
        }

        return {
            ...state,
            sidebarExpanded: newState
        }
    }),
);

export const getAppState = (state: AppState) => state;
export const getSidebarExpanded = (state: AppState) => state.sidebarExpanded;