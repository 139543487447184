import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AdminTasksState, adapter } from '../reducers/admin.task.reducers';

export const selectTaskState = createFeatureSelector<AdminTasksState>('admin_tasks');

export const { selectAll: selectAllTasks } = adapter.getSelectors(selectTaskState);

export const selectTasksByStatus = (status: string) => createSelector(
  selectAllTasks,
  (tasks) => tasks.filter(task => task.status === status)
);

export const selectAllTasksArray = createSelector(
  selectTaskState,
  (state: AdminTasksState) => Object.values(state.entities) // Convert entities to array
);

export const selectTasksLoading = createSelector(
  selectTaskState,
  (state: AdminTasksState) => state.loading
);

export const selectTaskError = createSelector(
  selectTaskState,
  (state: AdminTasksState) => state.error
);