import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromEvents from '../reducers/event.reducers';

export const getRootEventsState = (state: fromRoot.GlobalAppState) => state.events;
export const getAllEvents = createSelector(getRootEventsState, fromEvents.selectAllEvents);
export const selectEventsLoading = createSelector(getRootEventsState, fromEvents.getEventsLoading);
export const getSingleEvent = (slug: string) => createSelector(getAllEvents, (allEvents) => {
    if(allEvents) {
        return allEvents.filter(event => {
            return event.slug === slug;
        })
    } else {
        return [];
    }
});

export const getFeaturedEvents = createSelector(getAllEvents, (allEvents) => {
    if(allEvents) {
        return allEvents.filter(event => {
            return event.featured && event.tickets_sold < event.available_tickets;
        })
    } else {
        return [];
    }
});

export const getFeaturedEventsCount = createSelector(getAllEvents, (allEvents) => {
    if(allEvents) {
        return allEvents.filter(event => {
            return event.featured;
        }).length;
    } else {
        return 0;
    }
});