import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, from, take } from 'rxjs';
import { GigSheet } from '../../models/gigsheet';

@Injectable({
  providedIn: 'root'
})
export class GigSheetsService {
  afs: AngularFirestore = inject(AngularFirestore);

  fetchAllGigSheets(): Observable<unknown> {
    const today = new Date();
    return this.afs.collection('gig_sheets', ref=>ref.orderBy('event_date_timestamp', 'desc'))
      .get();
  }

  storeNewGigSheet(gigsheet: GigSheet) {
    return from(this.afs.collection("gig_sheets").add(gigsheet)).pipe(
      take(1),
    );
  }
}